import { Button, Grid, Typography, useTheme } from '@mui/material';
import CreditCard from '@op/shared/assets/images/credit-card-blank.png';
import React from 'react';
import { OPBoldTypograpghy } from '../styled';

interface ICreditCardTypeWidgetProps {
  cardNumber: string;
  textView?: 'onlyNumber' | 'typeWithNumber' | 'button' | 'image';
}

export const creditCardType = (cc: string): string => {
  const cardPatterns = [
    { type: 'VISA', pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
    { type: 'AMEX', pattern: /^3[47][0-9]{13}$/ },
    { type: 'MASTERCARD', pattern: /^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$/ },
    { type: 'DISCOVER', pattern: /^6011[0-9]{12}[0-9]*$|^62[24568][0-9]{13}[0-9]*$|^6[45][0-9]{14}[0-9]*$/ },
    { type: 'DINERS', pattern: /^3[0689][0-9]{12}[0-9]*$/ },
    { type: 'JCB', pattern: /^35[0-9]{14}[0-9]*$/ },
    { type: 'CHINA_UNION_PAY', pattern: /^62[0-9]{14}[0-9]*$|^81[0-9]{14}[0-9]*$/ },
    { type: 'ENROUTE', pattern: /^2014[0-9]{12}[0-9]*$|^2149[0-9]{12}[0-9]*$/ },
  ];

  for (const { type, pattern } of cardPatterns) {
    if (pattern.test(cc)) {
      return type;
    }
  }
  return '';
};

export const CreditCardTypeWidget: React.FC<ICreditCardTypeWidgetProps> = ({ cardNumber, textView }) => {
  const theme = useTheme();

  if (!cardNumber) {
    return null;
  }

  const cardType = creditCardType(cardNumber);
  const lastNumbers = `****${cardNumber.substring(cardNumber.length - 4)}`;

  const renderCardType = () => {
    if (cardNumber.length <= 0) {
      return;
    }
    const cardType = creditCardType(cardNumber);
    if (cardType === '') {
      return <Typography variant="caption">{cardType}</Typography>;
    }
    return (
      <Button color="primary" disableElevation disableRipple variant="contained" size="small">
        {cardType}
      </Button>
    );
  };

  const renderTextView = () => (
    <Grid container justifyContent="flex-end" columnSpacing={0.5} alignItems="center">
      <Grid item>
        <Button
          variant="contained"
          disableRipple
          size="small"
          onClick={() => {}}
          sx={{
            backgroundColor: theme.palette.grey[500],
            ':hover': {
              backgroundColor: theme.palette.grey[500],
            },
          }}>
          {cardType}
        </Button>
      </Grid>
      <Grid item>
        <OPBoldTypograpghy style={{ color: theme.palette.grey[600], fontSize: '14px' }}>
          {lastNumbers}
        </OPBoldTypograpghy>
      </Grid>
    </Grid>
  );

  const renderOnlyNumber = () => (
    <OPBoldTypograpghy style={{ color: theme.palette.greyShades.contrastText, fontSize: '14px' }}>
      {lastNumbers}
    </OPBoldTypograpghy>
  );

  const renderImageView = () => {
    return (
      <div style={{ position: 'relative' }}>
        <img src={CreditCard} alt="" style={{ height: 40, width: 80 }} />
        <OPBoldTypograpghy
          style={{
            position: 'absolute',
            bottom: 10,
            right: 5,
            fontSize: 8,
            color: theme.palette.timeFrame.contrastText,
          }}>
          {lastNumbers}
        </OPBoldTypograpghy>
      </div>
    );
  };

  switch (textView) {
    case 'typeWithNumber':
      return renderTextView();
    case 'onlyNumber':
      return renderOnlyNumber();
    case 'image':
      return renderImageView();
    default:
      return renderCardType();
  }
};
