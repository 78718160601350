import { BuyOrSell, LegType } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import lodash from 'lodash';
import { IPlaceOrderLeg } from './confirm-order-ticket';
import { ITradeStatationPosition } from './getPositions';
import { IQuoteSnapshot } from './quote-snapshot';

export interface ITradeProps {
  underlyingSymbol: string;
  qty: string;
  symbol: string;
  accountID?: string;
  longShort?: string;
  action?: 'trade' | 'closeAtMarket' | 'roll';
  initiator: 'positions' | 'opTradeTicket';
}

export interface IOrderTicketPosition {
  underlyingSymbol: string;
  description: string;
  side: string;
  qty: string;
  positions: number;
  expiry: Date | undefined;
  strike: number | undefined;
  type: string;
  symbol: string;
  accountID?: string;
  buyOrSell?: string;
  isClose?: boolean;
  orderSides?: string[];
}

export interface IBidMidAskProps {
  ask: string;
  mid: string;
  bid: string;
  qty?: string;
  isBuy?: boolean;
  isSecurity?: boolean;
}

// =============== Bid/Mid/Ask Calculation =================

const calAsk = (tsOrderTicketPosition: IBidMidAskProps) => {
  const absQtyByPos = Math.abs(Number(tsOrderTicketPosition.qty));
  const netAsk = tsOrderTicketPosition.ask;
  if (tsOrderTicketPosition.isSecurity) {
    return (Number(netAsk) * absQtyByPos) / 100;
  }
  return Number(netAsk) * absQtyByPos;
};

const calBid = (tsOrderTicketPosition: IBidMidAskProps) => {
  const absQtyByPos = Math.abs(Number(tsOrderTicketPosition.qty));
  const netBid = tsOrderTicketPosition.bid;
  if (tsOrderTicketPosition.isSecurity) {
    return (Number(netBid) * absQtyByPos) / 100;
  }
  return Number(netBid) * absQtyByPos;
};

const netAsk = (absQty: number, tsOrderTicketPosition: IBidMidAskProps, isBuyPosition: boolean, size?: number) => {
  const ask = isBuyPosition ? calAsk(tsOrderTicketPosition) : calBid(tsOrderTicketPosition);
  const bid = isBuyPosition ? calBid(tsOrderTicketPosition) : calAsk(tsOrderTicketPosition);
  let max: number;
  if (size > 1) {
    max = isBuyPosition ? Math.max(Math.abs(ask), Math.abs(bid)) : Math.min(Math.abs(bid), Math.abs(ask)); // -> logic-2
  } else {
    max = Math.max(Math.abs(ask), Math.abs(bid)); // -> logic-1
  }
  // const max = Math.max(Math.abs(ask), Math.abs(bid)); // -> logic-1
  // const max = isBuyPosition ? Math.max(Math.abs(ask), Math.abs(bid)) : Math.min(Math.abs(bid), Math.abs(ask)); // -> logic-2
  const netAskValue = absQty === 0 ? 0 : max / absQty;
  return isBuyPosition ? netAskValue.toString() : (-netAskValue).toString();
};

const netBid = (absQty: number, tsOrderTicketPosition: IBidMidAskProps, isBuyPosition: boolean, size?: number) => {
  const bid = isBuyPosition ? calBid(tsOrderTicketPosition) : calAsk(tsOrderTicketPosition);
  const ask = isBuyPosition ? calAsk(tsOrderTicketPosition) : calBid(tsOrderTicketPosition);
  let min: number;
  if (size > 1) {
    min = isBuyPosition ? Math.min(Math.abs(bid), Math.abs(ask)) : Math.max(Math.abs(ask), Math.abs(bid)); // -> logic-2
  } else {
    min = Math.min(Math.abs(ask), Math.abs(bid)); // -> logic-1
  }
  // const min = Math.min(Math.abs(ask), Math.abs(bid)); // -> logic-1
  // const min = isBuyPosition ? Math.min(Math.abs(bid), Math.abs(ask)) : Math.max(Math.abs(ask), Math.abs(bid)); // -> logic-2
  const netBidValue = absQty === 0 ? 0 : min / absQty;
  return isBuyPosition ? netBidValue.toString() : (-netBidValue).toString();
};

const netMid = (absQty: number, tsOrderTicketPosition: IBidMidAskProps, isBuyPosition: boolean) => {
  const ask = calAsk(tsOrderTicketPosition);
  const bid = calBid(tsOrderTicketPosition);
  const midPrice = (ask + bid) / 2;
  const netMidValue = absQty === 0 ? 0 : midPrice / absQty;
  return isBuyPosition ? netMidValue.toString() : (-netMidValue).toString();
};

export const getBidMidAskValues = (tsOrderTicketPositions: IBidMidAskProps[], absQty?: number) => {
  if (!tsOrderTicketPositions) {
    return undefined;
  }
  const size = tsOrderTicketPositions.length;
  const calculatedBidMidAsk: IBidMidAskProps[] = tsOrderTicketPositions.map((pos: IBidMidAskProps) => {
    const ask = netAsk(absQty, pos, pos.isBuy, size);
    const bid = netBid(absQty, pos, pos.isBuy, size);
    const mid = netMid(absQty, pos, pos.isBuy);
    return {
      ask,
      mid,
      bid,
    };
  });
  const natural = lodash.sumBy(calculatedBidMidAsk, (i) => Number(i.ask));
  const mid = lodash.sumBy(calculatedBidMidAsk, (i) => Number(i.mid));
  const bid = lodash.sumBy(calculatedBidMidAsk, (i) => Number(i.bid));
  const bidMidAskValue: IBidMidAskProps = {
    ask: Math.abs(natural).toFixed(2).toString(),
    mid: Math.abs(mid).toFixed(2).toString(),
    bid: Math.abs(bid).toFixed(2).toString(),
  };
  return bidMidAskValue;
};

export const bidMidAskfromSnapShot = (
  quoteSnapshot: IQuoteSnapshot,
  tsOrderTicketPositions: IOrderTicketPosition[],
) => {
  const isSingleSecurityPosition = hasOnlySecurity(tsOrderTicketPositions);
  const absQty = !isSingleSecurityPosition
    ? absQuantity(tsOrderTicketPositions)
    : absQuantity(tsOrderTicketPositions) / 100;
  const values = quoteSnapshot?.quotes.map((q) => {
    const position = tsOrderTicketPositions.find((pos) => pos.symbol === q.symbol);
    const netAsk = Number(q.ask);
    const netbid = Number(q.bid);
    return {
      ask: netAsk.toString(),
      bid: netbid.toString(),
      mid: ((netAsk + netbid) / 2).toString(),
      qty: position.qty,
      isBuy: position.buyOrSell === BuyOrSell.BUY,
      isSecurity: position.type === LegType.SECURITY,
    } as IBidMidAskProps;
  });
  const bidMidAskValues = getBidMidAskValues(values, absQty);
  return bidMidAskValues;
};

// ====================================================================

export const getActionName = (pos: ITradeProps, size: number, type: LegType) => {
  let format = '{buyOrSell} {openOrClose}';
  // default side on trade single security position
  if (size === 1 && type === LegType.SECURITY && pos.action === 'trade') {
    let result: string;
    if (pos.longShort === 'Short') {
      result = pos.initiator === 'positions' ? 'Buy to Cover' : 'Sell Short';
    } else {
      result = pos.initiator === 'positions' ? 'Sell' : 'Buy';
    }
    return result.toUpperCase();
  }
  // default side on trade options / covered-call from positions
  if (size >= 1 && pos.initiator === 'positions' && pos.action === 'trade') {
    const result = pos.longShort === 'Short' ? 'Buy to Close' : 'Sell to Close';
    return result.toUpperCase();
  }
  // default side on trade options / covered-call from op-tradeTicket
  let formatObj = {
    buyOrSell: Number(pos.qty) > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
    openOrClose: 'to Open',
  };
  // default side on close at market from positions
  if (pos.action === 'closeAtMarket') {
    formatObj.buyOrSell = BuyOrSell.SELL;
    formatObj.openOrClose = 'to Close';
  }
  let result = formatting.formatStr(format, formatObj);
  return result.toUpperCase();
};

export const absQuantity = (tsOrderTicketPositions: IOrderTicketPosition[]) => {
  if (!tsOrderTicketPositions) {
    return undefined;
  }
  const quantities = tsOrderTicketPositions.map((pos) => {
    return Math.abs(Number(pos.qty));
  });
  if (quantities.length === 0) {
    return 0;
  }
  let minQuantity = Math.min(...quantities);
  if (!isFinite(minQuantity) || isNaN(minQuantity)) {
    minQuantity = 0;
  }
  return minQuantity;
};

export const hasOnlySecurity = (tsOrderTicketPositions: IOrderTicketPosition[]) => {
  return tsOrderTicketPositions.length === 1 && tsOrderTicketPositions[0].type === LegType.SECURITY;
};

// ==================== Creating TsOrderTicketPositions Array ===============

export const checkCloseFlag = (actionName: string) => {
  const closeFlags = ['SELL', 'SELL TO CLOSE', 'BUY TO CLOSE', 'BUY TO COVER'];
  return closeFlags.includes(actionName);
};

const splittingSymbol = (symbol: string) => {
  const split = symbol.split(' ');
  const [expiry, callOrPut, strike] =
    split.length > 1
      ? [split[1].substring(0, 6), split[1].substring(6, 7), split[1].substring(7)]
      : [undefined, undefined, undefined];
  return { expiry, callOrPut, strike };
};

export const buildOrderTicketPositions = (tradeProps: ITradeProps[]) => {
  const orderTicketPositions: IOrderTicketPosition[] = tradeProps.map((pos) => {
    const obj = splittingSymbol(pos.symbol);
    const isStock = !obj.callOrPut;
    const expiry = isStock ? undefined : DateTimeHelper.toExpiry(obj.expiry);
    const strike = isStock ? undefined : Number(obj.strike);
    const type = isStock ? LegType.SECURITY : obj.callOrPut === 'P' ? LegType.PUT : LegType.CALL;
    const actionName = getActionName(pos, tradeProps.length, type);
    const absQty = Math.abs(Number(pos.qty));
    const symbol = formatSlashDotSymbol(pos.underlyingSymbol, !isStock);

    let position: IOrderTicketPosition = {
      underlyingSymbol: formatSlashDotSymbol(pos.underlyingSymbol),
      description: isStock ? symbol : `${symbol} ${DateTimeHelper.format(new Date(expiry))} ${strike} ${type}`,
      side: actionName,
      qty: actionName.includes('BUY') ? absQty.toString() : '-' + absQty.toString(),
      positions: 0,
      expiry: expiry ? DateTimeHelper.resolveExpiry(new Date(expiry)) : undefined,
      strike: strike,
      type: type,
      symbol: isStock ? symbol : `${symbol} ${obj.expiry}${obj.callOrPut}${obj.strike}`,
      // symbol: formatDotSymbol(pos.underlyingSymbol, !isStock),
      accountID: pos.accountID,
      buyOrSell: actionName.includes('BUY') ? BuyOrSell.BUY : BuyOrSell.SELL,
      isClose: checkCloseFlag(actionName), // TODO-fix
    };
    position.buyOrSell = pos.action === 'closeAtMarket' ? BuyOrSell.SELL : position.buyOrSell;
    return position;
  });
  // setting the order-sides to show in dropdown
  orderTicketPositions.forEach((e) => {
    e.orderSides = hasOnlySecurity(orderTicketPositions)
      ? e.side.includes('BUY TO COVER') || e.side.includes('SELL SHORT')
        ? ['BUY TO COVER', 'SELL SHORT']
        : ['BUY', 'SELL']
      : ['SELL TO OPEN', 'BUY TO OPEN', 'SELL TO CLOSE', 'BUY TO CLOSE'];
  });
  return orderTicketPositions;
};

// ============================================================================

const getTradeActionName = (leg: IOrderTicketPosition, tsOrderTicketPositions: IOrderTicketPosition[]) => {
  // if (leg.type === LegType.SECURITY && hasOnlySecurity(tsOrderTicketPositions)) {
  //   return leg.side.replace(/\s/g, '').includes('BUY') ? 'BUY' : 'SELL';
  // }
  return leg.side.replace(/\s/g, '');
};

export const getLegsForOrderRequest = (
  tsOrderTicketPositions: IOrderTicketPosition[],
  tsTradeProps?: ITradeProps[],
) => {
  const legs: IPlaceOrderLeg[] = tsOrderTicketPositions.map((leg: IOrderTicketPosition, index: number) => {
    let newLeg: IPlaceOrderLeg = {
      // option symbol is being constructed in BE
      symbol: formatSlashDotSymbol(leg.underlyingSymbol, leg.type !== LegType.SECURITY),
      expression: '',
      tradeAction: getTradeActionName(leg, tsOrderTicketPositions),
      quantity: Math.abs(Number(leg.qty)).toString(),
      expiryDate: leg.expiry ? DateTimeHelper.formatExpiryDate(leg.expiry) : '',
      actionSymbol: leg.type === LegType.SECURITY ? 'None' : leg.type,
      strikePrice: leg.strike ? leg.strike.toString() : '',
    };
    if (tsTradeProps && tsTradeProps[0].action === 'closeAtMarket') {
      // for closing covercall / options Short Positions
      if (tsTradeProps.length >= 1) {
        newLeg.tradeAction = tsTradeProps[index].longShort === 'Short' ? 'BUYTOCLOSE' : newLeg.tradeAction;
      }
      // for closing single security position
      if (tsTradeProps.length === 1 && leg.type === LegType.SECURITY) {
        newLeg.tradeAction = tsTradeProps[index].longShort === 'Short' ? 'BUYTOCOVER' : 'SELL';
      }
    }
    return newLeg;
  });
  return legs;
};

// ============================================================================

// export const getOrderSides = (initiator?: string) => {
//   const sides = ['SELL TO OPEN', 'BUY TO OPEN', 'SELL TO CLOSE', 'BUY TO CLOSE'];
//   if (initiator === 'positions') {
//     return sides;
//   }
//   return sides.filter((s) => s.includes('OPEN'));
// };

export const getOrderSides = (isClose: boolean) => {
  const sides = ['SELL TO OPEN', 'BUY TO OPEN', 'SELL TO CLOSE', 'BUY TO CLOSE'];
  return sides;
  // return sides.filter((s) => (isClose ? s.includes('CLOSE') : s.includes('OPEN')));
};

/*
  Input: SPY.ARCX -> Symbol: SPY
  Input: SPY.ARCX 250131C602 -> Symbol: SPY
  Input: C/PR/N.ARCX 240213P722 -> Symbol: C/PR/N
  Input: C/PR/N.ARCX -> Symbol: C/PR/N
*/
export const symbolWithoutExchangeCode = (symbol: string) => {
  const match = symbol.match(/^([^.\s]+)/);
  return match ? match[1] : '';
};

// function to format slash or dot symbol
/*
  symbol: BRK/B & C/PR/N
    isOptionSymbol: false, result: BRK.B & C.PN
    isOptionSymbol: true, result: BRKB & CPN

  symbol: BRK.B & C.PN
    isOptionSymbol: false, result: BRK.B & C.PN
    isOptionSymbol: true, result: BRKB & CPN
*/
export const formatSlashDotSymbol = (
  originalSymbolName: string,
  isOptionSymbol?: boolean,
  fromOP?: boolean,
): string => {
  const slashRegex = fromOP ? /[\/]/ : /[\/.]/;
  const symbolTermsArray = originalSymbolName.split(slashRegex);

  if (symbolTermsArray.length === 1) {
    return originalSymbolName;
  }

  let result = symbolTermsArray[0] + '.';

  if (isOptionSymbol) {
    result = result.replace(/\./g, '');
  }

  for (let i = 1; i < symbolTermsArray.length; i++) {
    if (i === symbolTermsArray.length - 1 || isOptionSymbol) {
      result += symbolTermsArray[i];
    } else {
      result += symbolTermsArray[i][0];
    }
  }

  return result;
};

export const formatIndexSymbol = (originalSymbolName: string) => {
  const symbolTermsArray = originalSymbolName.split('/');
  let result = symbolTermsArray[0] + '.';
  if (symbolTermsArray.length > 1) {
    result += symbolTermsArray
      .slice(1)
      .map((word) => word[0])
      .join('');
    return '$' + result;
  }

  return '$' + originalSymbolName;
};

export const checkExistingPositionByAccountID = (
  tsPositions: ITradeStatationPosition[],
  selectedAccounts: string[],
  position: IOrderTicketPosition,
) => {
  if (!tsPositions || tsPositions.length === 0 || selectedAccounts.length > 1) {
    return undefined;
  }
  const existingPosition = tsPositions.find((p) => p.accountID === selectedAccounts[0] && p.symbol === position.symbol);
  return existingPosition;
};
