import { cloneDeep } from 'lodash';
import { atom, selector } from 'recoil';
import {
  ISubscriptionFeature,
  ISubscriptionFeatureDetail,
  ISubscriptionPaymentForm,
  ISubscriptionPlanPrice,
  ISubscriptionPlanPricesModel,
  IUserSubscriptionFeatures,
  IUserSubscriptionResponse,
  defaultSubscriptionPaymentData,
} from '../../models';
import { SubscriptionStatus, UserSubscriptionPeriod } from '../../models/enums/enums';
import { guardRecoilDefaultValue } from '../trade';

export type SubscriptionScreens =
  | 'CurrentSubscription'
  | 'ChangeSubscription'
  | 'Cancelsubscription'
  | 'SuspendedSubscription'
  | 'NoSubscription';

export type StepScreens = 'changePlan' | 'addOn' | 'cardInfo' | 'summary' | 'congrats';

export interface ISelectedPlanModels {
  bundles: ISubscriptionPlanPrice[];
  addOns: ISubscriptionPlanPrice[];
}

export const reverseStepMap: Record<number, StepScreens> = {
  0: 'changePlan',
  1: 'addOn',
  2: 'cardInfo',
  3: 'summary',
  4: 'congrats',
};

export const subscriptionCurrentPaymentState = atom<ISubscriptionPaymentForm>({
  key: 'subscriptionCurrentpaymentStateKey',
  default: defaultSubscriptionPaymentData,
});

export const paymentCardTypeState = atom<string>({
  key: 'paymentCardTypeStateKey',
  default: '',
});

export const subscriptionCurrentState = atom<ISubscriptionFeature | undefined>({
  key: 'subscriptionCurrentStateKey',
  default: undefined,
});

export const selectedSubscriptionState = atom<ISubscriptionFeature | undefined>({
  key: 'selectedSubscriptionStateKey',
  default: undefined,
});

export const subscriptionCurrentScreenState = atom<SubscriptionScreens>({
  key: 'subscriptionCurrentScreenStateKey',
  default: undefined,
});

export const subscriptionCurrentStatusState = atom<SubscriptionStatus | undefined>({
  key: 'subscriptionStatusStateKey',
  default: undefined,
});

export const userSubscriptionFeaturesDataState = atom<IUserSubscriptionFeatures>({
  key: 'userSubscriptionFeaturesDataStateKey',
  default: undefined,
});

export const subscriptionPlanPricesModelState = atom<ISubscriptionPlanPricesModel>({
  key: 'subscriptionPlanPricesModelStateKey',
  default: undefined,
});

export const subscriptionPlanTypeState = atom<'Monthly' | 'Annual'>({
  key: 'subscriptionPlanTypeStateKey',
  default: 'Annual',
});

export const cancelPlanTypeState = atom<{ plan: ISubscriptionFeature | undefined }>({
  key: 'cancelPlanTypeStateKey',
  default: { plan: undefined },
});

export const paymentDetailsErrorState = atom<ISubscriptionPaymentForm>({
  key: 'paymentDetailsErrorStateKey',
  default: defaultSubscriptionPaymentData,
});

export const activeStepState = atom<number>({
  key: 'activeStepStateKey',
  default: 0,
});

export const addSubscriptionErrorState = atom<boolean>({
  key: 'addSubscriptionErrorStateKey',
  default: false,
});

export const addSubscriptionResponseState = atom<IUserSubscriptionResponse[]>({
  key: 'addSubscriptionResponseStateKey',
  default: undefined,
});

export type AddSubscriptionLoadingStatus = 'default' | 'summary' | 'confirm';
export const addSubscriptionLoadingState = atom<AddSubscriptionLoadingStatus>({
  key: 'addSubscriptionLoadingStateKey',
  default: 'default',
});

export const addSubscriptionSummaryState = atom<ISubscriptionFeatureDetail[]>({
  key: 'addSubscriptionSummaryStateKey',
  default: undefined,
});

export const updateCardAndBillingState = atom<boolean>({
  key: 'updateCardAndBillingStateKey',
  default: false,
});

export const userSubscriptionFeaturesState = selector<IUserSubscriptionFeatures>({
  key: 'userSubscriptionFeaturesStateKey',
  get: async ({ get }) => {
    const userFeatures = get(userSubscriptionFeaturesDataState);
    if (!userFeatures) {
      return;
    }
    return userFeatures;
  },
  set: ({ set }, userFeatures) => {
    if (guardRecoilDefaultValue(userFeatures)) {
      return;
    }
    if (!userFeatures) {
      set(subscriptionCurrentScreenState, 'NoSubscription');
      set(subscriptionCurrentStatusState, SubscriptionStatus.NO_SUBSCRIPTIONS);
      set(subscriptionCurrentState, undefined);
      return;
    }
    const currentFeatures = userFeatures.features.find((f) => f.title === UserSubscriptionPeriod.CURRENT);
    const pastFeatures = userFeatures.features.find((f) => f.title === UserSubscriptionPeriod.PAST);
    const currentSubscription = currentFeatures.subscriptionList.find((f) => f.featureType === 'Bundle');
    const pastSubscription = pastFeatures.subscriptionList.find((f) => f.featureType === 'Bundle');
    // currentFeatures.subscriptionList = [];
    const value = currentSubscription ? currentSubscription : pastSubscription;
    let screenvalue: SubscriptionScreens;

    if (!currentSubscription || !pastSubscription) {
      set(userSubscriptionFeaturesDataState, userFeatures);
      set(subscriptionCurrentState, value);
      set(subscriptionCurrentStatusState, 'NoSubscription' as SubscriptionStatus);
      set(subscriptionCurrentScreenState, 'NoSubscription');
      return;
    }
    switch (value.subscriptionStatus) {
      case SubscriptionStatus.ACTIVE:
        screenvalue = 'CurrentSubscription';
        break;
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.CANCELED_NOT_EXPIRED:
        screenvalue = 'CurrentSubscription';
        break;
      // For the expired user and activeTrial User, need to show choose-plan widget directly
      case SubscriptionStatus.EXPIRED:
        screenvalue = 'CurrentSubscription';
        break;
      case SubscriptionStatus.ACTIVE_TRIAL:
        screenvalue = 'CurrentSubscription';
        break;
      case SubscriptionStatus.EXPIRED_TRIAL:
      case SubscriptionStatus.EXPIRED_EXTENDED_TRIAL:
        screenvalue = 'ChangeSubscription';
        break;
      case SubscriptionStatus.SUSPENDED_NOT_EXPIRED:
      case SubscriptionStatus.SUSPENDED:
        screenvalue = 'SuspendedSubscription';
        break;
      case null:
      default:
        screenvalue = 'NoSubscription';
        break;
    }
    set(userSubscriptionFeaturesDataState, userFeatures);
    set(subscriptionCurrentState, value);
    set(subscriptionCurrentStatusState, value.subscriptionStatus);
    set(subscriptionCurrentScreenState, screenvalue);
  },
});

export const subscriptionPlanPriceState = selector<ISubscriptionPlanPrice[] | undefined>({
  key: 'subscriptionPlanPriceStateKey',
  get: async ({ get }) => {
    const subscriptionPlanPrices = get(subscriptionPlanPricesModelState);
    if (!subscriptionPlanPrices) {
      return;
    }
    return subscriptionPlanPrices.prices;
  },
  set: ({ set, get }, pricingModels) => {
    if (guardRecoilDefaultValue(pricingModels)) {
      return;
    }
    let planPrices = get(subscriptionPlanPricesModelState);
    if (!planPrices) {
      return;
    }
    const clonedPlanPrices = cloneDeep(planPrices);
    clonedPlanPrices.prices = pricingModels;
    set(subscriptionPlanPricesModelState, clonedPlanPrices);
  },
});

export const selectedPlansState = selector<ISelectedPlanModels | undefined>({
  key: 'selectedPlansStateKey',
  get: async ({ get }) => {
    const subscriptionPlanPrices = get(subscriptionPlanPricesModelState);
    if (!subscriptionPlanPrices) {
      return;
    }
    const selectedBundels = subscriptionPlanPrices.prices.filter(
      (p) => p.isSelected && p.featureType === 'Bundle' && !p.isActivePlan,
    );
    const selectedAddOns = subscriptionPlanPrices.prices.filter(
      (p) => p.isSelected && p.featureType === 'AddOn' && !p.isActivePlan,
    );

    return {
      bundles: selectedBundels,
      addOns: selectedAddOns,
    };
  },
});

export const stepperUpdaterState = selector<StepScreens>({
  key: 'stepperUpdaterStateKey',
  get: () => {
    throw new Error('This is only a setter');
  },
  set: ({ set }, value) => {
    const stepMap: Record<StepScreens, number> = {
      changePlan: 0,
      addOn: 1,
      cardInfo: 2,
      summary: 3,
      congrats: 4,
    };
    const stepScreen = stepMap[value as StepScreens] || 0;
    set(activeStepState, stepScreen);
  },
});

export const isActivePlanChangedState = selector({
  key: 'isActivePlanChangedStateKey',
  get: ({ get }) => {
    const subscriptionPlanPrices: ISubscriptionPlanPricesModel = get(subscriptionPlanPricesModelState);
    const activeStep = get(activeStepState);

    // Function to compare selected plans with active plans
    const isPlanChanged = (selectedPlan: ISubscriptionPlanPrice, activePlan: ISubscriptionPlanPrice | undefined) => {
      if (!activePlan) return true; // If there's no active plan, it has changed
      return (
        selectedPlan.featureKey !== activePlan.featureKey ||
        selectedPlan.planType !== activePlan.planType ||
        selectedPlan.planPrice !== activePlan.planPrice
      );
    };

    // Get all active and selected plans
    const activeBundles = subscriptionPlanPrices.prices.filter(
      (plan) => plan.isActivePlan && plan.featureType === 'Bundle',
    );
    const selectedBundles = subscriptionPlanPrices.prices.filter(
      (plan) => plan.isSelected && plan.featureType === 'Bundle',
    );

    const activeAddOns = subscriptionPlanPrices.prices.filter(
      (plan) => plan.isActivePlan && plan.featureType === 'AddOn',
    );
    const selectedAddOns = subscriptionPlanPrices.prices.filter(
      (plan) => plan.isSelected && plan.featureType === 'AddOn',
    );

    // Check if any selected bundle has changed compared to active ones
    const bundlesChanged = selectedBundles.some((selectedPlan) => {
      const activePlan = activeBundles.find((activePlan) => activePlan.featureKey === selectedPlan.featureKey);
      return isPlanChanged(selectedPlan, activePlan);
    });

    // Check if any selected add-on has changed compared to active ones
    const addOnsChanged = selectedAddOns.some((selectedPlan) => {
      const activePlan = activeAddOns.find((activePlan) => activePlan.featureKey === selectedPlan.featureKey);
      return isPlanChanged(selectedPlan, activePlan);
    });

    if (activeStep === 0) {
      return true; // Make intial bundle selection button unchanged thus passing always true
    }

    // Return true if either bundles or add-ons have changed, otherwise false
    return bundlesChanged || addOnsChanged;
  },
});
