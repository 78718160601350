import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ISubscriptionFeature } from '@op/shared/src/models';
import { EventType, PaymentSubscriptionStatus, SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  cancelPlanTypeState,
  isDeleteRequested,
  selectedSubscriptionState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
} from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { LanguageSelectorWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OptionsPlayDivider } from '../styled';
import { SubscriptionCardGridView } from './components';
import { CreditCardTypeWidget } from './credit-card-type-widget';
import { FeatureList } from './feature-list';

type Props = {
  plan: ISubscriptionFeature;
  index: number;
};

export const CanceledSubscriptionWidget: React.FC<Props> = ({ plan, index }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const deleteRequested = useRecoilValue(isDeleteRequested);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const setCancelInitiator = useSetRecoilState(cancelPlanTypeState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const isCancelledByUser = plan.paymentSubscriptionStatus === PaymentSubscriptionStatus.CanceledByUser;
  const actionDisabled =
    plan.subscriptionStatus === SubscriptionStatus.CANCELED ||
    plan.subscriptionStatus === SubscriptionStatus.CANCELED_NOT_EXPIRED ||
    deleteRequested ||
    index !== 2;

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const cancelSummary = (plan: ISubscriptionFeature) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid container item xs={2.5} alignItems={'center'}>
          <Grid item xs={9}>
            <Tooltip title={plan.featureLabel} placement="top-start">
              <OPBoldTypograpghy variant="subtitle2" color={theme.palette.timeFrame.contrastText}>
                {plan.featureLabel}
              </OPBoldTypograpghy>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <FeatureList benefitList={plan.benefitsList} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>{plan.planType}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>
            {DateTimeHelper.format(plan.effectiveDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.warning.dark}>
            {DateTimeHelper.format(plan.endDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <OPBoldTypograpghy color={theme.palette.warning.dark} sx={{ marginRight: '5px' }}>
              {t('subscriptions.cancelled')}
            </OPBoldTypograpghy>
            <IconComponent name="warning" stroke={theme.palette.timeFrame.contrastText} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const cancelDetails = (plan: ISubscriptionFeature) => {
    return (
      <Grid container rowSpacing={1}>
        <SubscriptionCardGridView
          label={t('subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.startDate')}
          value={DateTimeHelper.format(plan.effectiveDate)}
        />
        <SubscriptionCardGridView label={t('subscriptions.endDate')} value={DateTimeHelper.format(plan.endDate)} />
        <SubscriptionCardGridView
          label={t('subscriptions.paymentDate')}
          value={DateTimeHelper.format(plan.paymentDate)}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.cardPay')}
          value={<CreditCardTypeWidget cardNumber={plan.creditCardNumber} textView={'onlyNumber'} />}
        />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy style={{ color: theme.palette.text.primary, fontSize: '14px' }}>
              {t('subscriptions.totalPayment')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy style={{ color: theme.palette.text.primary, fontSize: '14px' }}>
              {NumberFormatHelper.toCurrency(plan.effectivePrice)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const onSubscribeNow = (plan: ISubscriptionFeature) => {
    logActivity('BUTTON', 'paymentsCanceledPlanChangeSubscribe');
    setSubscriptionCurrentScreen('ChangeSubscription');
    setActiveStep('changePlan');
    setSelectedSubscription(plan);
  };

  const changePlanButton = (plan: ISubscriptionFeature) => {
    if (actionDisabled) {
      return;
    }
    return (
      <Grid item xs={4}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            logActivity('BUTTON', 'paymentsCanceledPlanSubscribe');
            setSubscriptionCurrentScreen('ChangeSubscription');
            plan.featureType === 'Bundle' ? setActiveStep('changePlan') : setActiveStep('addOn');
            setSelectedSubscription(plan);
          }}>
          <OPBoldTypograpghy variant="button">{t('subscriptions.changePlan')}</OPBoldTypograpghy>
        </Button>
      </Grid>
    );
  };

  const subscribeNowButton = (plan: ISubscriptionFeature) => {
    if (!isCancelledByUser) {
      return;
    }
    return (
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: isCancelledByUser ? 'flex-start' : 'flex-end' }}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onSubscribeNow(plan)}
          sx={{
            backgroundColor: theme.palette.success.main,
            ':hover': { backgroundColor: theme.palette.success.main },
          }}>
          <OPBoldTypograpghy variant="button">{t('subscriptions.subscribeNow')}</OPBoldTypograpghy>
        </Button>
      </Grid>
    );
  };

  const cancelSubscriptionButton = (plan: ISubscriptionFeature) => {
    if (plan.paymentSubscriptionStatus === PaymentSubscriptionStatus.CanceledByUser) {
      return;
    }
    return (
      <Grid item xs={4}>
        <Button
          disabled={true}
          fullWidth
          variant="contained"
          onClick={() => {
            logActivity('BUTTON', 'paymentsCancelSubscribe');
            setSubscriptionCurrentScreen('Cancelsubscription');
            setCancelInitiator({ plan: plan });
          }}>
          <OPBoldTypograpghy variant="button">{t('subscriptions.cancelSubscription')}</OPBoldTypograpghy>
        </Button>
      </Grid>
    );
  };

  const cancelActions = (plan: ISubscriptionFeature) => {
    if (!isCancelledByUser && actionDisabled) {
      return;
    }
    return (
      <AccordionActions sx={{ backgroundColor: theme.palette.greyShades.main, padding: 0.5 }}>
        <Grid
          container
          spacing={1}
          alignItems={'center'}
          px={0.5}
          justifyContent={isCancelledByUser ? 'space-between' : 'flex-end'}>
          <Grid item xs={4}></Grid>
          {changePlanButton(plan)}
          {subscribeNowButton(plan)}
          {/* {cancelSubscriptionButton(plan)} */}
        </Grid>
      </AccordionActions>
    );
  };

  return (
    <Grid item xs={12} my={0.5}>
      <Accordion
        disableGutters
        defaultExpanded={index === 0}
        sx={{
          border: `2px solid ${theme.palette.primary.light}`,
          ':hover': { border: `2px solid ${theme.palette.primary.main}` },
          borderRadius: 1,
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
          id={`panel${index}-header`}
          sx={{
            backgroundColor: theme.palette.error.main,
            paddingY: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}>
          {cancelSummary(plan)}
        </AccordionSummary>
        <AccordionDetails>{cancelDetails(plan)}</AccordionDetails>
        {cancelActions(plan)}
      </Accordion>
    </Grid>
  );
};
