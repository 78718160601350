//@ts-ignore
import Axios from 'axios';
import {
  EmbedderSingleTokenRequest,
  ISignUpResponseProps,
  Account,
  VerifyCredentialsModel,
  SignUpModel,
  SignUpResponse,
  TokenRequest,
  TokenResponse,
} from '../models';
import ApplicationContext from '../models/how/application-context';
import { ErrorResponse } from '../view-models/responses/error-response';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';

export const apiEndpoingMap = new Map<string, string>([
  ['localhost:3000', 'https://telluride-api.optionsplay.com'],
  ['bromley-dev.optionsplay.com', 'https://bromley-api.optionsplay.com'],
  ['new-staging.optionsplay.com', 'https://api-staging.optionsplay.com'],
  ['usfe-staging.optionsplay.com', 'https://usbe-staging.optionsplay.com'],
  ['new.optionsplay.com', 'https://api.optionsplay.com'],
  ['bromley-no.optionsplay.com', 'https://bromley-no-api.optionsplay.com'],
  ['new-nordic-staging.optionsplay.com', 'https://api-nordic-staging.optionsplay.com'],
  ['app-nordic.optionsplay.com', 'https://api-nordic.optionsplay.com'],
  ['bromley-ca.optionsplay.com', 'https://bromley-ca-api.optionsplay.com'],
  ['new-tmx-staging.optionsplay.com', 'https://api-tmx-staging.optionsplay.com'],
  ['tmx.optionsplay.com', 'https://api-tmx.optionsplay.com'],
  ['bromley-global.optionsplay.com', 'https://bromley-global-api.optionsplay.com'],
  ['app-ca-staging.optionsplay.com', 'https://api-ca-staging.optionsplay.com'],
  ['app-ca.optionsplay.com', 'https://api-ca.optionsplay.com'],
  ['new-global-staging.optionsplay.com', 'https://api-global-staging.optionsplay.com'],
  ['new-global.optionsplay.com', 'https://api-global.optionsplay.com'],
  ['telluride-app.optionsplay.com', 'https://telluride-api.optionsplay.com'],
  ['telluride-staging.optionsplay.com', 'https://telluride-api-staging.optionsplay.com'],
  ['qt.optionsplay.com', 'https://qtapi.optionsplay.com'],
]);

const getBaseUrl = () => {
  const baseApiEndPoint = apiEndpoingMap.get(location.host);
  if (!baseApiEndPoint || baseApiEndPoint.trim() === '') {
    throw new Error('Please provide api end point');
  }
  return `${baseApiEndPoint}/api`;
  // this.singalrEndpoint = `${baseApiEndPoint}/signalr`;
};

export const embedderLogin = async (request: string): Promise<ResponseViewModel<TokenResponse>> => {
  const response = new ResponseViewModel<TokenResponse>();
  try {
    const url = `${getBaseUrl()}/authentication/token`;
    const axiosResponse = await Axios.post<TokenResponse>(url, request, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Headers': 'origin, Content-Type, Accept',
      },
      withCredentials: true,
    });
    response.data = axiosResponse.data;
  } catch (exception) {
    response.errors.push(ErrorResponse.fromMessage('Invalid Credentials'));
  }
  return response;
};

//TODO: nas: token is hardcoded
export const getWidgetLoaderTokens = async (
  origin: string,
): Promise<Record<'hostToValidateEventsFrom' | 'host' | 'requestValidationToken', string>> => {
  const url = `${getBaseUrl()}/widgetLoader/widgetToken`;
  const urlWithQs = `${url}?domain=${encodeURIComponent(origin)}`;
  const response = await Axios.post<any>(urlWithQs);
  return response.data;
};

export const getSecurityModel = async (token: string | undefined) => {
  try {
    const url = 'authentication/securityModel';
    if (token === undefined || token.trim() === '') {
      return undefined;
    }
    const response = await ApiService.instance.get<any>(url);
    if (response.hasErrors || !response.data) {
      return undefined;
    }
    ApplicationContext.accessToken = token;
    return Account.fromJson(response.data);
  } catch {
    console.error('Unable to fetch user information.');
  }
  return undefined;
};

export class AuthenticationService extends BaseApiService {
  private static _instance: AuthenticationService;

  private constructor() {
    super('/authentication');
    ApiService.instance.init(AuthenticationService.onError, AuthenticationService.onLoading);
  }

  public static get instance(): AuthenticationService {
    if (AuthenticationService._instance == null) {
      AuthenticationService._instance = new AuthenticationService();
    }
    return AuthenticationService._instance;
  }

  public token = async (request: TokenRequest): Promise<ResponseViewModel<TokenResponse>> => {
    const response = new ResponseViewModel<TokenResponse>();
    try {
      const url = `${this.serviceUrl}/token`;
      const req = {
        username: request.username,
        password: request.password,
        grant_type: request.grant_type,
        scope: 'login_v2',
      };
      const data = new URLSearchParams(req);
      const axiosResponse = await Axios.post<TokenResponse>(url, data.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Request-Headers': 'origin, Content-Type, Accept',
        },
        withCredentials: true,
      });

      response.data = axiosResponse.data;
    } catch (exception) {
      response.errors.push(ErrorResponse.fromMessage('You have entered an invalid email or password.'));
    }
    return response;
  };

  public refreshToken = async (refreshToken: string): Promise<ResponseViewModel<TokenResponse>> => {
    const response = new ResponseViewModel<TokenResponse>();
    try {
      const url = `${this.serviceUrl}/token`;
      const req = {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      };
      const data = new URLSearchParams(req);
      const axiosResponse = await Axios.post<TokenResponse>(url, data.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Request-Headers': 'origin, Content-Type, Accept',
        },
        withCredentials: true,
      });
      response.data = axiosResponse.data;
    } catch (exception) {
      response.errors.push(ErrorResponse.fromMessage('You have entered an invalid email or password.'));
    }
    return response;
  };

  public singleSiginToken = async (request: EmbedderSingleTokenRequest): Promise<ResponseViewModel<TokenResponse>> => {
    const response = new ResponseViewModel<TokenResponse>();
    try {
      const url = `${this.serviceUrl}/token`;
      const req = {
        client_id: request.client_id,
        grant_type: request.grant_type,
        theme: request.theme,
        userId: request.userId,
        requestValidationToken: request.requestValidationToken,
      };
      const data = new URLSearchParams(req);
      const axiosResponse = await Axios.post<TokenResponse>(url, data.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Request-Headers': 'origin, Content-Type, Accept',
        },
        withCredentials: true,
      });

      response.data = axiosResponse.data;
    } catch (exception) {
      response.errors.push(ErrorResponse.fromMessage('Invalid client id'));
    }
    return response;
  };

  public canUseEmail = async (request: string) => {
    const url = `/authentication/canUseEmail`;
    const response = await ApiService.instance.post<string, boolean>(url, request);
    return response;
  };

  public signUp = async (request: SignUpModel): Promise<SignUpResponse | null> => {
    const url = '/authentication/signUp';

    const response = await ApiService.instance.post<SignUpModel, SignUpResponse>(url, request);
    const signUpResponse = SignUpResponse.fromJson(response.data as unknown as ISignUpResponseProps);

    if (response.errors.length > 0) {
      return null;
    }

    return signUpResponse;
  };

  public verifyCredentials = async (request: VerifyCredentialsModel) => {
    const url = `/authentication/VerifyCredentials`;
    const response = await ApiService.instance.post<VerifyCredentialsModel, boolean>(url, request);
    return response;
  };

  private static onError = (errorCode: number): void => {
    console.error('Error in authentication-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public getAccessKeyAndToken = async (token: string) => {
    const url = `/Authentication/ValidateHashToken/${token}`;
    const response = await ApiService.instance.get(url);
    return response;
  };

  public verifyToken = async (token: string | null) => {
    try {
      const url = `/Authentication/ping`;
      if (token === null || token.trim() === '') {
        return false;
      }
      const response = await ApiService.instance.get<string>(url);
      if (response.hasErrors || !response.data) {
        return false;
      }
      return response.data.toUpperCase() === 'PONG';
    } catch {
      return false;
    }
  };
}
