import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ThemeType, { getThemeType } from '@op/shared/src/models/theme-type';
import { themeState, userSettingState } from '@op/shared/src/states';
import { useRecoilState, useSetRecoilState } from 'recoil';

import React from 'react';
import { cloneDeep, MarketRegion } from '@op/shared/src/models';

export const RegionSettingWidget: React.FC = () => {
  const regions = [
    {
      region: MarketRegion.US,
      name: 'us',
    },
    {
      region: MarketRegion.TMX,
      name: 'ca',
    },
  ];
  const [setting, setSetting] = useRecoilState(userSettingState);

  if (!setting) {
    return <></>;
  }

  const onChange = (value: string): void => {
    const region = value as MarketRegion;
    const clone = cloneDeep(setting);
    clone.preferredRegion = region;
    setSetting(clone);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="activeTheme"
        value={setting.preferredRegion}
        onChange={(_, value) => onChange(value)}
        sx={{ flex: 1, flexDirection: 'row' }}>
        {regions.map((t) => (
          <FormControlLabel
            key={t.region}
            labelPlacement="end"
            value={t.region}
            control={<Radio color="primary" />}
            label={t.region}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
