import { Permissions } from '@op/shared/src/models';
import { accountState, configurationState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { getMarketRegion } from '@op/shared/src/states/trade/trade-states';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useFallbackSymbol = () => {
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const setNotifications = useSetRecoilState(notificationsState);

  const getFallbackSymbol = (symbol?: string) => {
    const [newSymbol, exchangeCode] = symbol.toUpperCase().split('.');
    const region = getMarketRegion(exchangeCode);
    const permissionAvailable = account.securityModel.hasPermission(region.toString() as Permissions);
    if (!permissionAvailable) {
      setNotifications([{ type: 'error', content: `${symbol} - ${region} permission is not available` }]);
    }
    if (permissionAvailable && symbol) {
      return symbol;
    }
    return configuration.marketIndexQuoteSymbol.toUpperCase();
  };

  return getFallbackSymbol;
};
