import { Account } from './account';
import { Permissions } from './enums/enums';
import ApplicationContext from './how/application-context';
import { CachedSecurity } from './quote/cached-security';

// For embedder region permission check
export const hasRegionAccess = (cachedData: CachedSecurity) => {
  const regionAccessFromConfig = ApplicationContext.configuration?.additionalData?.regionAccess;
  if (!regionAccessFromConfig) return false;

  const accessibleRegions = regionAccessFromConfig.split(',');
  return accessibleRegions.includes(cachedData.region);
};

// if AAPL is passed, allowed symbol to load only if US region access
// if AAPL.(US_EXCHANGE), allowed symbol to load only if US region access
// if AAPL.(CA_EXCHANGE), and if have US region access, deny to load respective symbol

// Get the region by account permissions
const getRegionByAccount = (account?: Account) => {
  if (account && account.isUSAllowed) return Permissions.US;
  if (account && account.isCanadaAllowed) return Permissions.CA;
  return Permissions.US; // Default to US
};

// Find symbol with accessible exchange code based on region permissions
export const getSymbolWithExchangeCode = (cachedData: CachedSecurity[], account?: Account) => {
  const regionAccessFromConfig = ApplicationContext.configuration?.isEmbeddingPlatform
    ? ApplicationContext.configuration?.additionalData?.regionAccess
    : getRegionByAccount(account);

  const accessibleRegions = regionAccessFromConfig.split(',');
  return cachedData.find((c) => accessibleRegions.includes(c.region));
};
