import { CachedSecurities, CachedSecurity, IConfiguration } from '@op/shared/src/models';
import { getSymbolWithExchangeCode, hasRegionAccess } from '@op/shared/src/models/common-helper';
import { EmbedderEventType, EventType, Permissions } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { getCachedSecurities } from '@op/shared/src/services';
import {
  accessTokenKey,
  accountState,
  cachedSecuritiesDataState,
  embedderSentimentAtom,
  fetchHowData,
  fetchRankData,
  fetchWhyData,
  howWhyRanksDataState,
  isHowWhyRanksDataLoading,
  isSymbolNotSupportedState,
  resetIncomeState,
  subViewState,
  tradingStrategiesSentimentAtom,
  viewState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

export const useFetchAndSetData = () => {
  const [isProcessing, setIsProcessing] = useRecoilState(isHowWhyRanksDataLoading);
  const navigate = useNavigate();
  const setHowWhyRanksData = useSetRecoilState(howWhyRanksDataState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [account, setAccount] = useRecoilState(accountState);
  const resetStrategySentiment = useResetRecoilState(tradingStrategiesSentimentAtom);
  const resetSubView = useResetRecoilState(subViewState);
  const resetView = useResetRecoilState(viewState);
  const resetIncome = useSetRecoilState(resetIncomeState);
  const resetEmbedderSentiment = useResetRecoilState(embedderSentimentAtom);
  const setIsSymbolNotSupported = useSetRecoilState(isSymbolNotSupportedState);
  const [cachedSecuritiesData, setCachedSecuritiesData] = useRecoilState(cachedSecuritiesDataState);
  /**
   * Load the application
   * Check for the application additionaldata - preload symbols are there not
   * Get the fetch cachesecurities with additiona data as param
   * set the cachesecurities recoil
   * check for the symbol presence from the above response
   * if(symbolpresense){
   *  - continue with howwhyranks
   * }
   * if(not presence){
   *
   * if(normal application){
   *  - load the previous symbol
   * }
   * else if (isembedder && !showSearchbarforunknownsymbol && !loadPreviousSymbol) {
   *    - empty quotebar message
   * }
   * else if (isembedder && showSearchbarforunknownsymbol && !loadPreviousSymbol) {
   *    - search bar + quotebar message
   * }
   * else if(isembedder && loadPreviousSymbol=true){
   *  - load the previous selcted symbol // nasdaq  merrill
   * }
   * read the configuration showSearchbarforunknownsymbol
   * if(!showSearchbarforunknownsymbol){
   *    - empty quotebar message
   * } else {
   *    - search bar + quotebar message
   * }
   * }
   */

  const fetchCachedSecuritiesData = async (allowedSecurities: string | undefined) => {
    if (cachedSecuritiesData) {
      return cachedSecuritiesData;
    }

    const cachedSecuritiesRes = await getCachedSecurities(allowedSecurities);
    if (cachedSecuritiesRes.hasErrors || !cachedSecuritiesRes.data) {
      return;
    }
    const cachedSecurities = CachedSecurities.fromData(cachedSecuritiesRes.data);
    if (!cachedSecurities) {
      return;
    }
    return cachedSecurities;
  };

  const splitSymbol = (symbol: string): [newSymbol: string, exchangeCode: string] => {
    const [newSymbol, exchangeCode] = symbol.toUpperCase()?.split(/\.(?=[^.]*$)/);
    return [newSymbol, exchangeCode];
  };

  const isSymbolExistsInCachedSecurities = (symbol: string, cachedSecurityData: CachedSecurity[]) => {
    if (!cachedSecurityData) {
      return false;
    }
    const [newSymbol, exchangeCode] = splitSymbol(symbol);
    const symbolInCached = cachedSecurityData.filter((cached) => cached.symbol === newSymbol);
    if (symbolInCached.length === 0) {
      return false;
    }
    if (exchangeCode && symbolInCached.length > 0) {
      return symbolInCached.some((s) => s.exchangeCode === exchangeCode);
    }
    return true;
  };

  const handleSymbolNotExists = (configuration: IConfiguration, symbol: string, sentiment?: string) => {
    if (
      configuration.isEmbeddingPlatform &&
      (configuration.additionalData?.loadPreviousSymbol === 'false' || !configuration.loadPreviousSymbol)
    ) {
      window.localStorage.setItem('embedSelectedSymbolStateKey', symbol.toUpperCase());
      setHowWhyRanksData({
        symbol: symbol.toUpperCase(),
        how: undefined,
        why: undefined,
        ranks: undefined,
      });
      setIsSymbolNotSupported(true);
      resetStrategySentiment();
      if (!sentiment) {
        resetEmbedderSentiment();
      }
      resetIncome({ reason: 'symbolChanged', shouldReset: true });
      resetView();
      resetSubView();
      return;
    }
    setNotifications([{ type: 'error', content: `Quote not found` }]);
    return;
  };

  const fetchAndSetData = async (
    symbol: string,
    ruleMatch?: string,
    isAnonymous?: boolean,
    shareId?: string,
    allowedSecurities?: string,
    sentiment?: string,
  ) => {
    let updatedSymbol = symbol;
    setIsSymbolNotSupported(false);
    if (isProcessing) {
      return true;
    }
    setIsProcessing(true);

    if (!ApplicationContext.configuration) {
      return;
    }
    const configuration = ApplicationContext.configuration;
    const additionalData = configuration?.additionalData;
    const allowedSecurities1 = additionalData
      ? ApplicationContext.configuration.additionalData?.allowedSecurities
      : undefined;
    let cachedSecurites: CachedSecurities | undefined = undefined;
    if (!isAnonymous) {
      cachedSecurites = await fetchCachedSecuritiesData(allowedSecurities1);
      if (!cachedSecurites || !cachedSecurites.data) {
        return;
      }
      const isSymbolExists = isSymbolExistsInCachedSecurities(updatedSymbol, cachedSecurites.data);

      if (!isSymbolExists) {
        handleSymbolNotExists(configuration, updatedSymbol, sentiment);
        setIsProcessing(false);
        return;
      }

      // Region permission check
      const [newSymbol, exchangeCode] = splitSymbol(updatedSymbol);
      const cachedSymbolData = cachedSecurites.data.filter((cached) => cached.symbol === newSymbol);

      if (!cachedSymbolData || cachedSymbolData.length === 0) {
        setNotifications([{ type: 'error', content: `Quote not found` }]);
        setIsProcessing(false);
        return;
      }

      if (!exchangeCode) {
        const symbolWithExchange = getSymbolWithExchangeCode(cachedSymbolData, account);
        if (symbolWithExchange) {
          updatedSymbol = `${symbolWithExchange.symbol}.${symbolWithExchange.exchangeCode}`;
        } else {
          if (!account) {
            setNotifications([{ type: 'error', content: `Quote not found` }]);
            setIsProcessing(false);
            return;
          }
          updatedSymbol = configuration.marketIndexQuoteSymbol;
        }
      } else {
        const symbolByExchange = cachedSymbolData.find((c) => c.exchangeCode === exchangeCode);
        if (!symbolByExchange) {
          setNotifications([{ type: 'error', content: `Invalid exchange code: ${exchangeCode}` }]);
          setIsProcessing(false);
          return;
        }
        const permissionAvailable = ApplicationContext.configuration?.isEmbeddingPlatform
          ? hasRegionAccess(symbolByExchange)
          : account.securityModel.hasPermission(symbolByExchange.region as Permissions);
        if (!permissionAvailable) {
          setNotifications([
            { type: 'error', content: `${symbol} - ${symbolByExchange.region} permission is not available` },
          ]);
          setIsProcessing(false);
          return;
        }
      }
    }
    const fallbackRanksSymbol = getFallbackSymbol();
    const [how, why, ranks, ranksFallback] = await Promise.all([
      fetchHowData(updatedSymbol, ruleMatch, isAnonymous, shareId),
      fetchWhyData(updatedSymbol, ruleMatch, isAnonymous, shareId),
      fetchRankData(updatedSymbol, isAnonymous, shareId),
      fallbackRanksSymbol && fallbackRanksSymbol !== ''
        ? fetchRankData(fallbackRanksSymbol, isAnonymous, shareId)
        : undefined,
    ]);
    if (how.hasErrors || why.hasErrors || ranks.hasErrors || !how.data || !why.data || !ranks.data) {
      setNotifications([{ type: 'error', content: `Quote not found` }]);
      if (how.errorCode === 403) {
        if (configuration.isEmbeddingPlatform) {
          setNotifications([{ type: 'error', content: `Unexpected error has occurred, please reload the page.` }]);
          setIsProcessing(false);
          return;
        }
        localStorage.removeItem('lightbox_state');
        localStorage.removeItem(accessTokenKey);
        setAccount(undefined);
        if (configuration.isEmbeddingPlatform) {
          window.parent.location.reload();
          return;
        }
        navigate('/login', { replace: true });
        window.location.reload();
      }
      setIsProcessing(false);
      return false;
    }
    fillFallbackSignal(ranksFallback, ranks);
    setCachedSecuritiesData(cachedSecurites);
    setHowWhyRanksData({
      symbol: updatedSymbol.toUpperCase(),
      how: how.data.howData,
      why: why.data,
      ranks: ranks.data,
    });
    resetStrategySentiment();
    if (!sentiment) {
      resetEmbedderSentiment();
    }
    resetIncome({ reason: 'symbolChanged', shouldReset: true });
    if (ApplicationContext.configuration?.isEmbeddingPlatform) {
      const message = { event: EmbedderEventType.SymbolChanged.toString(), data: updatedSymbol.toUpperCase() };
      window.parent.postMessage(JSON.stringify(message), '*');
    }
    const key = ApplicationContext.configuration?.isEmbeddingPlatform
      ? 'embedSelectedSymbolStateKey'
      : 'selectedSymbolStateKey';
    window.localStorage.setItem(key, updatedSymbol.toUpperCase());
    let payload: any = { quoteType: account && account.hasRealTimeQuotes ? 'realTime' : 'delayed' };
    if (account && account.widgetUserId) {
      payload['userId'] = account.widgetUserId;
    }
    ApplicationContext.userActivityHub?.logActivity(
      'selectedTradeIdeaSymbol',
      'selectedTradeIdeaSymbol',
      JSON.stringify(payload),
      EventType.Click,
      updatedSymbol.toUpperCase(),
    );
    setIsProcessing(false);
    return true;
  };

  const fillFallbackSignal = (ranksFallback: any | undefined, ranks: any) => {
    if (!ranksFallback) {
      return;
    }
    const ivRank = ranks.data?.find((s: any) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase());
    if (ivRank) {
      return;
    }
    const ivRankFallback = ranksFallback.data?.find(
      (s: any) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase(),
    );
    if (!ivRankFallback) {
      return;
    }
    ranks.data.push(ivRankFallback);
  };

  const getFallbackSymbol = () => {
    const additionalData = JSON.parse(window.localStorage.getItem('additionalData') || '{}');
    if (!additionalData.shouldFallBack) {
      return undefined;
    }
    return additionalData.fallbackRanksSymbol?.trim();
  };
  return fetchAndSetData;
};
