import { Button, Grid } from '@mui/material';
import { selectedPlansState, stepperUpdaterState, updateCardAndBillingState } from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';

interface Props {
  title?: string;
  cardInfo?: boolean;
}

export const SubscriptionHeaderTabWidget: React.FC<Props> = ({ title = '', cardInfo = false }) => {
  const { t } = useContext(LocalizationContext);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const selectedPlans = useRecoilValue(selectedPlansState);
  const updateCardAndBillingInfo = useRecoilValue(updateCardAndBillingState);

  const renderUpdateContent = () => {
    return (
      <Grid item xs={12}>
        <Button size="large" variant="text" disableRipple disableElevation sx={{ px: 4, cursor: 'text', height: 40 }}>
          <OPBoldTypograpghy variant="body1">{t('subscriptions.updateCreditBilling')}</OPBoldTypograpghy>
        </Button>
      </Grid>
    );
  };

  const renderContent = () => {
    return (
      <Grid item xs={12}>
        {title && (
          <Button variant="text" disableRipple disableElevation sx={{ px: 4, cursor: 'text', height: 40 }}>
            <OPBoldTypograpghy variant="body1">{title}</OPBoldTypograpghy>
          </Button>
        )}

        <Button
          variant="contained"
          onClick={() => setActiveStep('changePlan')}
          sx={{
            padding: 0,
            ml: 2,
            height: 40,
            width: 100,
          }}>
          <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.changePlan')}</OPBoldTypograpghy>
        </Button>
        {cardInfo && (
          <Button
            disabled={selectedPlans.addOns.length === 0}
            variant="contained"
            sx={{ padding: 0, height: 40, width: 100, ml: 0.5 }}
            onClick={() => setActiveStep('addOn')}>
            <OPBoldTypograpghy variant="subtitle2">{`${t('subscriptions.addOn')}`}</OPBoldTypograpghy>
          </Button>
        )}
      </Grid>
    );
  };

  return <SubscriptionHeaderWidget label={updateCardAndBillingInfo ? renderUpdateContent() : renderContent()} />;
};
