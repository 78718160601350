import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { Sorts, cloneDeep } from '@op/shared/src/models';
import { types } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { IShortPutReport } from '@op/shared/src/models/reports/short-put';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { fetchSharedTradeByOccHash } from '@op/shared/src/services';
import { getShortPutReports } from '@op/shared/src/services/reports-service';
import {
  accountState,
  isDataLoadingState,
  reportSearchState,
  strategyShareDataState,
  subViewState,
  viewState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import {
  filtersShortPutState,
  shortPutDataCachedState,
  shortPutFilters,
  shortPutPortfolioPositionsCountState,
  shortPutState,
  shortPutWatchlistQuotesCountState,
  sortsShortPutState,
} from '@op/shared/src/states/reports/short-put-states';
import React, { Suspense, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ViewFiltersByCount2 } from '../../common';
import { useFetchAndSetData } from '../../states/use-fetch-and-set-data';
import { useNavigateSearch } from '../../states/use-navigate-search';
import { OPBoldTypograpghy } from '../../styled/styled-components';
import { isTSTradeTicketPreserved } from '../../tradestation/states/ts-ticket-states';
import { AnnualizedReturnFilterWidget } from '../annualized-return-filter-widget';
import { HasEarningsFilterWidget2 } from '../has-earnings-filter-widget';
import { IdeaDropDownWidget } from '../idea-dropdown-widget';
import { FilterContainer } from '../idea-filter-widget';
import { IdeaSecondRow } from '../idea-second-row';
import { IvRankFilterWidget2 } from '../iv-rank-filter-widget';
import { LiquidityRatingFilterWidget2 } from '../liquidity-rating-filter-widget';
import { IShortPutProps, Pager } from '../pager';
import { ThirdRowContainer } from '../third-row-container';
import { WhatLoaderWidget } from '../what-loader-widget';
import { ShortPut } from './short-put';
import { useFallbackSymbol } from '../../states/use-fallback-symbol';

export const ShortPuts: React.FC = () => {
  const fetchAndSetData = useFetchAndSetData();
  const navigateSearch = useNavigateSearch();
  const [searchParams] = useSearchParams();
  const resetSearchText = useResetRecoilState(reportSearchState);
  const [shortPuts, setShortPuts] = useRecoilState(shortPutState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);
  const [shortPutSorts, setShortPutSorts] = useRecoilState(sortsShortPutState);
  const [spFilters, setSpFilters] = useRecoilState(filtersShortPutState);
  const linkedPortfoliosCount = useRecoilValue(shortPutPortfolioPositionsCountState);
  const linkedWatchListsCount = useRecoilValue(shortPutWatchlistQuotesCountState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setStrategyShareData = useSetRecoilState(strategyShareDataState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const [items, setItems] = useState<IShortPutProps[] | undefined>();
  const account = useRecoilValue(accountState);
  const theme = useTheme();
  const getFallbackSymbol = useFallbackSymbol();

  const logActivity = (symbol: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'selectedShortPutSymbol',
      'selectedShortPutSymbol',
      symbol,
      'click',
      symbol,
    );
  };

  const onItemSelected = async (symbol: string, hash: string) => {
    if (!hash || hash.trim() === '') {
      return;
    }
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    const tradeResponse = await fetchSharedTradeByOccHash(hash);
    if (
      !tradeResponse ||
      tradeResponse.hasErrors ||
      !tradeResponse.data ||
      symbol.trim().toUpperCase() !== tradeResponse.data.symbol.trim().toUpperCase()
    ) {
      setIsDataLoading(false);
      setNotifications([{ type: 'error', content: 'Incorrect hash information' }]);
      return;
    }
    const data = tradeResponse.data as any;
    const fallbackSymbol = getFallbackSymbol(data.symbol);
    const isSuccessful = await fetchAndSetData(fallbackSymbol);
    if (!isSuccessful) {
      setIsDataLoading(false);
      setNotifications([{ type: 'error', content: 'Insufficent data' }]);
      return;
    }
    data.targetDate = formatting.getCurrentDate();
    setStrategyShareData(data);
    setView('income');
    setSubView(isTSTradeTicketPreserved() ? 'tradeTicket' : 'expand');
    logActivity(symbol);
    navigateSearch({ symbol: data.symbol, hash: hash, type: 'Income' });
    setIsDataLoading(false);
  };

  const processUrl = async () => {
    const symbol = searchParams.get('symbol');
    const hash = searchParams.get('hash');
    await onItemSelected(symbol, hash);
  };

  const mapItems = (data: IShortPutReport[] | undefined) => {
    if (!data) {
      return [];
    }
    const reports = data.map((t) => {
      return {
        symbol: t.symbol,
        companyName: t.companyName,
        type: 'shortPut',
        hasEarnings: t.earningsFlag,
        strike: String(t.strikePrice),
        daysToExpiry: String(t.daysToExpiry),
        annualizedReturn: NumberFormatHelper.toPercentage(
          NumberFormatHelper.roundPercentageValue(t.annualizedReturn),
          'auto',
          0,
        ),
        signals: t.signals,
        portfolios: t.portfolios || [],
        watchlists: t.watchlists || [],
        url: t.url,
      } as IShortPutProps;
    });
    return reports;
  };

  useEffect(() => {
    if (!account || !account.canWorkWithReports) {
      return;
    }
    (async () => {
      await processUrl();
    })();
    return () => {
      resetSearchText();
    };
  }, []);

  useEffect(() => {
    if (shortPuts && shortPuts.data) {
      setItems(mapItems(shortPuts.data));
      return;
    }
    (async () => {
      const response = await getShortPutReports();
      if (response.hasErrors || !response.data) {
        setItems([]);
        setNotifications([{ type: 'error', content: 'Unable to get short puts reports.' }]);
        return;
      }
      setShortPuts(response.data);
      setItems(mapItems(response.data.data));
    })();
  }, [shortPuts]);

  if (!shortPuts || !account) {
    return <WhatLoaderWidget />;
  }

  const type = types.find((f) => f.name === 'shortPut');
  const hasEarningFilter = spFilters.find((g) => g.name === 'hasEarnings');
  const ivRankFilter = spFilters.find((g) => g.name === 'ivRank');
  const liquidityFilter = spFilters.find((g) => g.name === 'liquidity');
  const annualizedReturnFilter = spFilters.find((g) => g.name === 'annualizedReturn');

  const isAllFiltersSelected = () => {
    let clone = cloneDeep(spFilters);
    clone = clone.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    return clone.flatMap((g) => g.filters.map((f) => f.selected)).every((s) => s === true);
  };

  const onFilterClick = (group: IFilterGroup) => {
    const clone = cloneDeep(spFilters);
    let group1 = clone.find((g) => g.name === group.name);
    if (!group1) {
      return;
    }
    const filtered = clone.filter((g) => g.name !== group.name);
    filtered.push(group);
    setSpFilters(filtered);
  };

  const onSelectAllFilters = () => {
    const groups = cloneDeep(spFilters);
    const clone = groups.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    const allSelected = isAllFiltersSelected();
    /* allSelected variable is used to select and deselect SelectAll Checkbox*/
    for (const group of clone) {
      for (const filter of group.filters) {
        filter.selected = !allSelected;
      }
    }
    setSpFilters(groups);
  };

  const onResetFilters = () => {
    const groups = cloneDeep(shortPutFilters);
    setSpFilters(groups);
  };

  const getFilter = (name: 'linkedPortfolios' | 'linkedWatchLists', count: number) => {
    const f = spFilters.find((g) => g.name === name);
    const clone = cloneDeep(f);
    if (clone.filters.length === 0) {
      return;
    }
    clone.filters[0].title = count.toString();
    return clone;
  };
  const renderInformation = () => {
    return (
      <Grid container>
        <Typography variant="body1" sx={{ py: 0.5, textAlign: 'justify' }}>
          Find the highest yielding income opportunities for short puts with our daily short put report. Providing yield
          analysis, earnings, liquidity, and statistics, allowing for quick decision making across an entire portfolio
          at once.
        </Typography>
        <OPBoldTypograpghy>{`Last Updated on: ${DateTimeHelper.getReportFormattedDate(
          shortPuts.created,
        )}`}</OPBoldTypograpghy>
      </Grid>
    );
  };
  return (
    <Suspense fallback={<WhatLoaderWidget />}>
      <Grid container>
        <Grid item xs={12} py={1}>
          <Paper sx={{ height: '100%', border: '1px solid transparent', borderRadius: 2 }} elevation={4}>
            <Grid container xs={12} rowSpacing={1} p={1}>
              <IdeaDropDownWidget count={shortPuts.shortPutCount} ideaType={type} />
              <IdeaSecondRow
                onSort={(sorts: Sorts) => setShortPutSorts(sorts)}
                selectedSorts={shortPutSorts}
                defaultSortOptions={Sorts.ShortPutDefaultSorts}
                Filters={
                  <FilterContainer
                    isSelectedAll={isAllFiltersSelected()}
                    onSelectAll={onSelectAllFilters}
                    onReset={onResetFilters}>
                    <HasEarningsFilterWidget2 filter={hasEarningFilter} onClick={onFilterClick} />
                    <IvRankFilterWidget2 filter={ivRankFilter} onClick={onFilterClick} />
                    <LiquidityRatingFilterWidget2 filter={liquidityFilter} onClick={onFilterClick} />
                    <AnnualizedReturnFilterWidget group={annualizedReturnFilter} onClick={onFilterClick} />
                  </FilterContainer>
                }
              />
              <ThirdRowContainer
                ideaType={type.name}
                createdOn={shortPuts.created}
                title="Short Put Opportunity"
                data={renderInformation()}
                refreshReport={resetShortPuts}>
                {account.canWorkWithPortfolios && (
                  <ViewFiltersByCount2
                    filter={getFilter('linkedPortfolios', linkedPortfoliosCount)}
                    onClick={onFilterClick}
                    icon="personalPortfolioIcon"
                    stroke={linkedPortfoliosCount ? theme.palette.primary.main : '#999999'}
                  />
                )}
                {account.canWorkWithWatchLists && (
                  <ViewFiltersByCount2
                    filter={getFilter('linkedWatchLists', linkedWatchListsCount)}
                    onClick={onFilterClick}
                    icon="personalWatchlistIcon"
                    stroke={linkedWatchListsCount ? theme.palette.primary.main : '#999999'}
                  />
                )}
              </ThirdRowContainer>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Pager
            data={items}
            getNode={(idea) => <ShortPut data={idea as IShortPutProps} onItemSelected={onItemSelected} />}
          />
        </Grid>
      </Grid>
    </Suspense>
  );
};
