import { Permissions, Role } from '../enums/enums';

import { SecurityModel } from '.';
import ApplicationContext from '../how/application-context';
import { isTmx } from '../languge-type';
import { TokenResponse } from './token-response';

export class Account {
  securityModel: SecurityModel | undefined;
  widgetName: string | undefined;
  widgetUserId: string | undefined;

  public static fromTokenResponse = (response: TokenResponse | undefined, name?: string, widgetUserId?: string) => {
    if (response === undefined) {
      throw new Error('response is undefined');
    }
    ApplicationContext.accessToken = response.access_token;
    ApplicationContext.refreshToken = response.refresh_token;
    const model = new Account();
    model.widgetName = name;
    model.widgetUserId = widgetUserId;
    model.securityModel = SecurityModel.fromData(response);
    return model;
  };

  static fromJson = (json: any | undefined) => {
    if (!json) {
      return undefined;
    }
    const model = new Account();
    model.securityModel = SecurityModel.fromJson(json);
    return model;
  };

  static fromSelf = (account: Account | undefined) => {
    if (!account) {
      return undefined;
    }
    const model = new Account();
    model.securityModel = SecurityModel.fromJson(account.securityModel);
    return model;
  };

  hasPermission = (permission: Permissions) => {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(permission);
  };

  hasAnyPermission = (permissions: Permissions[]) => {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission(permissions);
  };

  get canAccessApplication() {
    if (!this.securityModel) {
      return false;
    }
    return this.canWorkWithTradeIdeas || this.canWorkWithWatchLists || this.canWorkWithPortfolios;
  }

  get canWorkWithTradeIdeas() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.VIEW_POWERHOUSE_O_P_I);
  }

  get canWorkWithWatchLists() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.WORK_WITH_WATCH_LISTS);
  }

  get canWorkWithPortfolios() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.VIEW_POWERHOUSE_O_P_P]);
  }

  get canSeeGlobalPortfolios() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.VIEW_GLOBAL_PORTFOLIOS]);
  }
  get canManagePortfolios() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.MANAGE_GLOBAL_PORTFOLIOS]);
  }

  get hasManualPortfolioUpoadAccess() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.MANUAL_PORTFOLIO);
  }

  get isAdmin() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasRole(Role.Admin);
  }

  get isViewOPPEnabled() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasRole(Role.ViewOPP);
  }

  get isValidSubscription() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([
      Permissions.VIEW_POWERHOUSE_O_P_I,
      Permissions.WORK_WITH_WATCH_LISTS,
      Permissions.VIEW_POWERHOUSE_O_P_P,
    ]);
  }

  get isAllowSymbolSearch() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.ALLOW_SYMBOL_SEARCH);
  }

  get isAllowEditUserWatchList() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.ALLOW_EDIT_USER_WATCHLIST);
  }

  get isAllowAmeritadeAccountLinking() {
    if (!this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.T_D_AMERITRADE_ACCOUNT_LINKING);
  }

  get isSearchBarClickable() {
    if (!this || !this.securityModel) {
      return false;
    }
    return isTmx() ? this.isAllowSymbolSearch : true;
  }

  get isEditWatchListClickable() {
    if (!this || !this.securityModel) {
      return false;
    }
    return isTmx() ? this.isAllowEditUserWatchList : true;
  }

  get isAllowUserWatchlist() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.ALLOW_USER_WATCHLIST);
  }

  get hasRealTimeQuotes() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.USE_REAL_TIME_QUOTES);
  }

  get isAllowTradeStationAccountLinking() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasPermission(Permissions.TRADE_STATION_ACCOUNT_LINKING);
  }

  get canWorkWithReports() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.VIEW_REPORTS]);
  }

  get isCanadaAllowed() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.CA]);
  }

  get isUSAllowed() {
    if (!this || !this.securityModel) {
      return false;
    }
    return this.securityModel.hasAnyPermission([Permissions.US]);
  }
}
