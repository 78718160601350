import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ISubscriptionFeature } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  cancelPlanTypeState,
  isDeleteRequested,
  selectedSubscriptionState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
} from '@op/shared/src/states';
import React, { useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LanguageSelectorWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OptionsPlayDivider } from '../styled';
import { SubscriptionCardGridView } from './components';
import { CreditCardTypeWidget } from './credit-card-type-widget';
import { FeatureList } from './feature-list';
import { ManageCardButton } from './manage-card-button';

type Props = {
  plan: ISubscriptionFeature;
  index: number;
};

export const ActiveSubscriptionWidget: React.FC<Props> = ({ plan, index }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const deleteRequested = useRecoilValue(isDeleteRequested);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const setCancelInitiator = useSetRecoilState(cancelPlanTypeState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionState);

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const shouldDisableButton = useCallback(() => {
    return deleteRequested && index !== 2;
  }, [deleteRequested, index]);

  const activeSummary = (plan: ISubscriptionFeature) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid container item xs={2.5} alignItems={'center'}>
          <Grid item xs={9}>
            <Tooltip title={plan.featureLabel} placement="top-start">
              <OPBoldTypograpghy variant="subtitle2" color={theme.palette.timeFrame.contrastText}>
                {plan.featureLabel}
              </OPBoldTypograpghy>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <FeatureList benefitList={plan.benefitsList} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>{plan.planType}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>
            {DateTimeHelper.format(plan.effectiveDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>
            {DateTimeHelper.format(plan.endDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>
            {DateTimeHelper.nextDate(plan.effectiveDate, plan.planType)}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const activeDetails = (plan: ISubscriptionFeature) => {
    return (
      <Grid container rowSpacing={1}>
        <SubscriptionCardGridView
          label={t('subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.startDate')}
          value={DateTimeHelper.format(plan.effectiveDate)}
        />
        <SubscriptionCardGridView label={t('subscriptions.endDate')} value={DateTimeHelper.format(plan.endDate)} />
        <SubscriptionCardGridView
          label={t('subscriptions.paymentDate')}
          value={DateTimeHelper.format(plan.paymentDate)}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.cardPay')}
          value={<CreditCardTypeWidget cardNumber={plan.creditCardNumber} textView={'onlyNumber'} />}
        />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy style={{ color: theme.palette.text.primary, fontSize: '14px' }}>
              {t('subscriptions.totalPayment')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy style={{ color: theme.palette.text.primary, fontSize: '14px' }}>
              {NumberFormatHelper.toCurrency(plan.effectivePrice)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const activeActions = (plan: ISubscriptionFeature) => {
    if (shouldDisableButton()) {
      return;
    }
    return (
      <AccordionActions sx={{ backgroundColor: theme.palette.greyShades.main, padding: 0.5 }}>
        <Grid container spacing={1} alignItems={'center'} px={0.5}>
          <ManageCardButton plan={plan} />
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                logActivity('BUTTON', 'paymentsActivePlanSubscribe');
                setSubscriptionCurrentScreen('ChangeSubscription');
                plan.featureType === 'Bundle' ? setActiveStep('changePlan') : setActiveStep('addOn');
                setSelectedSubscription(plan);
              }}>
              <OPBoldTypograpghy variant="button">{t('subscriptions.changePlan')}</OPBoldTypograpghy>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              sx={{ ':hover': { backgroundColor: theme.palette.error.main } }}
              onClick={() => {
                logActivity('BUTTON', 'paymentsActivePlanUnsubscribe');
                setSubscriptionCurrentScreen('Cancelsubscription');
                setCancelInitiator({ plan: plan });
              }}>
              <OPBoldTypograpghy variant="button">{t('subscriptions.cancelSubscription')}</OPBoldTypograpghy>
            </Button>
          </Grid>
        </Grid>
      </AccordionActions>
    );
  };

  return (
    <Grid item xs={12} my={0.5}>
      <Accordion
        disableGutters
        defaultExpanded={index === 0}
        sx={{
          border: `2px solid ${theme.palette.primary.light}`,
          ':hover': { border: `2px solid ${theme.palette.primary.main}` },
          borderRadius: 1,
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
          id={`panel${index}-header`}
          sx={{
            backgroundColor: theme.palette.success.main,
            paddingY: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}>
          {activeSummary(plan)}
        </AccordionSummary>
        <AccordionDetails>{activeDetails(plan)}</AccordionDetails>
        {activeActions(plan)}
      </Accordion>
    </Grid>
  );
};
