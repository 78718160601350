import { Globalization, IConfiguration, IGlobalization, isTmx, LanguageType } from '@op/shared/src/models';
import { MarketRegion } from '@op/shared/src/models/enums/enums';
import { globalizationState } from '@op/shared/src/states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export const localeIds = [
  {
    id: 1033,
    type: LanguageType.English,
  },
  {
    id: 2052,
    type: LanguageType.Chinese,
  },
  {
    id: 1053,
    type: LanguageType.Swedish,
  },
  {
    id: 3084,
    type: LanguageType.FrenchCanadian,
  },
];

export const useGlobalization = () => {
  const { i18n } = React.useContext(LocalizationContext);
  const setGlobalization = useSetRecoilState(globalizationState);
  const localLanguage = window.localStorage.getItem('i18nextLng') as LanguageType;

  /**
   * This function updates the active language in the globalization object
   * and changes the language in i18n accordingly.
   */
  const updateLanguageInGlobalization = (globalization: Globalization, lang: LanguageType) => {
    const selectedLanguage = globalization.items.find((item) => item.name === lang);
    if (selectedLanguage) {
      globalization.active = selectedLanguage; // Set the selected language as active
      i18n.changeLanguage(selectedLanguage.name); // Change the language in i18n
    }
  };

  /**
   * Configures globalization state by creating a globalization object
   * and setting the active language using the selected language.
   */
  const configureGlobalization = (resources: IGlobalization[], lang: LanguageType) => {
    const globalization = Globalization.fromData(resources, lang); // Create globalization object from resources
    setGlobalization(globalization); // Update the Recoil state with the new globalization data
    updateLanguageInGlobalization(globalization, lang); // Set the active language and change in i18n
  };

  /**
   * Sets the language based on the localeId from the config.
   * If no match is found, defaults to English.
   */
  const setLanguageByLocaleId = (resources: IGlobalization[], localeId: number | undefined) => {
    const locale = localeIds.find((locale) => locale.id === localeId); // Find locale based on ID
    const language = locale?.type || LanguageType.English; // Use locale type or default to English
    configureGlobalization(resources, language); // Apply the selected language
  };

  /**
   * Handles globalization configuration during login.
   * If a localeId is available, it uses that; otherwise, it defaults to localStorage or English.
   */
  const handleLoginGlobalization = (resources: IGlobalization[], config: IConfiguration) => {
    if (config?.userSettings?.localeId) {
      // Set language based on the localeId from userSettings
      setLanguageByLocaleId(resources, config.userSettings.localeId);
    } else {
      // Default to localStorage language or English if not available
      configureGlobalization(resources, localLanguage || LanguageType.English);
    }
  };

  /**
   * Handles globalization configuration during page refresh.
   * If no language is found in localStorage, it defaults to the market region's language preference.
   */
  const handleRefreshGlobalization = (resources: IGlobalization[], config: IConfiguration) => {
    if (!localLanguage) {
      // If no language in localStorage, determine default language based on market region
      const defaultLanguage = config.marketRegion === MarketRegion.Nordic ? LanguageType.Swedish : LanguageType.English;
      configureGlobalization(resources, defaultLanguage);
    } else {
      // Use language from localStorage if available
      configureGlobalization(resources, localLanguage);
    }
  };

  /**
   * Extracts locales from the embedderLocales if the platform is embedding and embedderLocales is valid.
   */
  const getEmbedderLocales = (config: IConfiguration) => {
    if (config.isEmbeddingPlatform && config.additionalData?.locales && config.additionalData.locales.trim() !== '') {
      return config.additionalData.locales.split(',').map((locale) => locale.trim() as LanguageType);
    }
    return null; // Return null if not embedding or embedderLocales is invalid
  };

  const getMatchingLocales = (locales: string[]) => {
    // Map of locale strings to LanguageType
    const localeMap: Record<string, LanguageType> = {
      'en-US': LanguageType.English,
      'fr-CA': LanguageType.FrenchCanadian,
      'zh-CN': LanguageType.Chinese,
      'sv-SE': LanguageType.Swedish, // Corrected LanguageType for Swedish
    };

    // Array of available globalization items
    const items: IGlobalization[] = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)' },
      { id: 1053, name: LanguageType.Swedish, fullName: 'Swedish (Sweden)' },
      { id: 2052, name: LanguageType.Chinese, fullName: 'Chinese (China)' },
      { id: 2054, name: LanguageType.FrenchCanadian, fullName: 'French (Canada)' },
    ];

    // Filter items where the localeMap contains a matching LanguageType
    const matchingItems = items.filter((item) => locales.some((locale) => localeMap[locale] === item.name));

    return matchingItems;
  };

  /**
   * Handles globalization configuration during page refresh.
   * If no language is found in localStorage, it defaults to the market region's language preference.
   */
  const handleEmbedderGlobalization = (config: IConfiguration, locale: string | undefined) => {
    const embedderLocales = ['en-US', 'fr-CA'];
    // const embedderLocales = getEmbedderLocales(config);
    const resources = getMatchingLocales(embedderLocales);
    configureGlobalization(resources, (locale || embedderLocales[0]) as LanguageType);
  };

  /**
   * This is the main function that decides which logic to apply based on whether it's a login or refresh.
   * It configures globalization accordingly by calling the appropriate functions.
   */
  const setGlobalizationItems = (
    response: ResponseViewModel<Globalization>,
    config: IConfiguration,
    triggerPoint: 'login' | 'reload' | 'embedder',
    locale?: string | undefined,
  ) => {
    if (!response && config.isEmbeddingPlatform) {
      handleEmbedderGlobalization(config, locale);
      return;
    }
    // TODO: May be need to check with if there is no response at any point of time
    const resources = response.data as unknown as IGlobalization[]; // Extract globalization resources from response
    if (triggerPoint === 'login') {
      // If the request is from login, apply login globalization configuration
      handleLoginGlobalization(resources, config);
    } else if (triggerPoint === 'reload') {
      // If the request is from page refresh, apply refresh globalization configuration
      handleRefreshGlobalization(resources, config);
    }
  };

  // Return the function that sets globalization items
  return setGlobalizationItems;
};
