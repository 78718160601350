import { Button, Grid } from '@mui/material';
import { ISubscriptionFeature } from '@op/shared/src/models';
import {
  selectedSubscriptionState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
  updateCardAndBillingState,
} from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';

interface Props {
  plan: ISubscriptionFeature;
}

export const ManageCardButton: React.FC<Props> = ({ plan }: Props) => {
  const { t } = useContext(LocalizationContext);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionState);
  const setUpdateBillingCardInfo = useSetRecoilState(updateCardAndBillingState);

  return (
    <Grid item xs={4}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          setSelectedSubscription(plan);
          setUpdateBillingCardInfo(true);
          setSubscriptionCurrentScreen('ChangeSubscription');
          setActiveStep('cardInfo');
        }}>
        <OPBoldTypograpghy variant="button">{t('subscriptions.manageCard')}</OPBoldTypograpghy>
      </Button>
    </Grid>
  );
};
