import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ISubscriptionFeature } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import {
  isDeleteRequested,
  selectedSubscriptionState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
} from '@op/shared/src/states';
import React, { useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { FeatureList } from './feature-list';

type Props = {
  plan: ISubscriptionFeature;
  index: number;
};

export const SuspendedSubscriptionWidget: React.FC<Props> = ({ plan, index }) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const deleteRequested = useRecoilValue(isDeleteRequested);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionState);

  const shouldDisableButton = useCallback(() => {
    return deleteRequested && index !== 2;
  }, [deleteRequested, index]);

  const suspendedSummary = (plan: ISubscriptionFeature) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid container item xs={2.5} alignItems={'center'}>
          <Grid item xs={9}>
            <Tooltip title={plan.featureLabel} placement="top-start">
              <OPBoldTypograpghy variant="subtitle2" color={theme.palette.timeFrame.contrastText}>
                {plan.featureLabel}
              </OPBoldTypograpghy>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <FeatureList benefitList={plan.benefitsList} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>{plan.planType}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.timeFrame.contrastText}>
            {DateTimeHelper.format(plan.effectiveDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <OPBoldTypograpghy color={theme.palette.warning.dark}>
            {DateTimeHelper.format(plan.endDate)}
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={2.5}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <OPBoldTypograpghy color={theme.palette.warning.dark} sx={{ marginRight: '5px' }}>
              {t('subscriptions.suspended')}
            </OPBoldTypograpghy>
            <IconComponent name="warning" stroke={theme.palette.timeFrame.contrastText} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const suspendedDetails = (plan: ISubscriptionFeature) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid item xs={9}>
          <Typography
            sx={{
              fontSize: 14,
            }}>
            {t('subscriptions.invalidCreditCardText')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            color="success"
            variant="contained"
            disabled={shouldDisableButton()}
            sx={{
              backgroundColor: theme.palette.success.main,
              ':hover': { backgroundColor: theme.palette.success.main },
            }}
            onClick={() => {
              logActivity('BUTTON', 'paymentsSuspendedPlanSubscribe');
              setSubscriptionCurrentScreen('ChangeSubscription');
              plan.featureType === 'Bundle' ? setActiveStep('changePlan') : setActiveStep('addOn');
              setSelectedSubscription(plan);
            }}>
            <OPBoldTypograpghy variant="button">{t('subscriptions.subscribeNow')}</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} my={0.5}>
      <Accordion
        disableGutters
        defaultExpanded={index === 0}
        sx={{
          border: `2px solid ${theme.palette.primary.light}`,
          ':hover': { border: `2px solid ${theme.palette.primary.main}` },
          borderRadius: 1,
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
          id={`panel${index}-header`}
          sx={{
            backgroundColor: theme.palette.error.main,
            paddingY: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}>
          {suspendedSummary(plan)}
        </AccordionSummary>
        <AccordionDetails>{suspendedDetails(plan)}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};
