import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Container, Grid, IconButton, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import { isUS } from '@op/shared/src/models';
import { ProfileDetailsService } from '@op/shared/src/services/profile-details-service';
import { accountState, configurationState, customizationState, lightboxSelectorState } from '@op/shared/src/states';
import {
  lightboxNotificationClick,
  lightboxState,
  unreadLightboxState,
} from '@op/shared/src/states/lightbox-open-close';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import AccountMenuWidget from '../side-menu/account-menu-widget';
import { LanguageSelectorWidget } from './language-selector-widget';
import { LogoWidget } from './logo-widget';
import { MarketDetailsWidget } from './market-details';
import { SubscriptionStatusWidget } from './subscription-status-widget';
import { UserVoiceWidget } from './uservoice-widget';

declare global {
  interface Window {
    UserVoice: Array<object>;
  }
}

export const HeaderWidget: React.FC = () => {
  const account = useRecoilValue(accountState);
  const customization = useRecoilValue(customizationState);
  const lightboxes = useRecoilValue(lightboxSelectorState);
  const [lightboxCount, setLightBoxCount] = useRecoilState(unreadLightboxState);
  const setOpenLightBox = useSetRecoilState(lightboxState);
  const setshowNotification = useSetRecoilState(lightboxNotificationClick);
  const setNotifications = useSetRecoilState(notificationsState);
  const configuration = useRecoilValue(configurationState);
  const theme = useTheme<Theme>();
  const UserVoice = window.UserVoice || [];
  const screenshotRoute = useMatch('/screenshot/:id');
  let loginRoute = useMatch('/login/');
  const { t } = useContext(LocalizationContext);

  if (!configuration || !customization || customization.isNasdaq) {
    return null;
  }
  if (screenshotRoute || loginRoute) {
    return <></>;
  }
  if (!account || !account.securityModel) {
    return (
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.paper,
          mb: 1,
        }}>
        <Container>
          {!customization?.isEmbeddingPlatform ? (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs="auto">
                <LogoWidget isHeader={true} />
              </Grid>
            </Grid>
          ) : null}
        </Container>
      </AppBar>
    );
  }

  const showSupportButtons = configuration.showSupportButtons;
  const showEducationAndGuide = customization.showEducationHubLink;
  const lightBox = configuration.lightbox;

  const lightBoxCountFunction = () => {
    let lightBoxUnRead = 0;
    lightboxes?.map((e) => {
      if (!e.isLightboxAccepted) {
        lightBoxUnRead++;
      }
    });
    setLightBoxCount(lightBoxUnRead);
  };

  const openHub = (): void => {
    window.open('https://www.optionsplay.com/hub');
  };

  const reportsLink = async () => {
    if (!configuration) {
      return '';
    }
    let uri: string = configuration.reportsSiteUrl;
    const response = await ProfileDetailsService.generateBromleyUserHashToken();
    if (response.data) {
      uri = uri + '?hashtoken=' + response.data.hashtoken;
    }
    window.open(uri);
  };

  const openLightBox = (): void => {
    if (lightboxes && lightboxes.length > 0) {
      setOpenLightBox(true);
      setshowNotification(true);
    } else {
      setNotifications([{ type: 'error', content: 'There is no LightBox content' }]);
    }
  };

  const renderUserVoice = () => {
    if (!showSupportButtons) {
      return;
    }
    return <UserVoiceWidget />;
  };

  const renderLightboxNotification = () => {
    if (lightBox === null || !account.canAccessApplication || customization.isEmbeddingPlatform) {
      return;
    }
    return (
      <IconButton sx={{ cursor: 'pointer' }} size="large">
        <Tooltip placement="top" title={t(`header.labels.notification`) as string} arrow>
          <Badge badgeContent={lightboxCount}>
            <NotificationsNoneOutlinedIcon
              onClick={() => openLightBox()}
              style={{ fontSize: 24, color: theme.palette.primary.dark }}
            />
          </Badge>
        </Tooltip>
      </IconButton>
    );
  };

  const renderEducationAndGuide = () => {
    if (!showEducationAndGuide || !isUS()) {
      return;
    }
    return (
      <IconButton sx={{ cursor: 'pointer' }} size="large">
        <Tooltip placement="top" title="Education & Resources" arrow>
          <MenuBookOutlinedIcon onClick={() => openHub()} style={{ fontSize: 24, color: theme.palette.primary.dark }} />
        </Tooltip>
      </IconButton>
    );
  };

  const renderReports = () => {
    if (!isUS() || !configuration.isSSO || customization.isEmbeddingPlatform) {
      return;
    }
    return (
      <IconButton sx={{ cursor: 'pointer' }} size="large">
        <Tooltip placement="top" title="Reports" arrow>
          <AssessmentOutlinedIcon
            onClick={() => reportsLink()}
            style={{ fontSize: 24, color: theme.palette.primary.dark }}
          />
        </Tooltip>
      </IconButton>
    );
  };

  const renderMenuItems = (): JSX.Element | null => {
    if (!account || !account.securityModel) {
      UserVoice.push(['hide']);
      return null;
    }
    // if (!customization.showHeaderMenus || !customization.showUserMenu) {
    //   return null;
    // }
    const accountName = account.securityModel.displayName;
    return (
      <Grid container alignItems="center" onLoad={() => lightBoxCountFunction()}>
        <Grid item xs="auto">
          {renderReports()}
        </Grid>
        <Grid item xs="auto">
          {renderEducationAndGuide()}
        </Grid>
        <Grid item xs="auto">
          {renderLightboxNotification()}
        </Grid>
        <Grid item xs="auto">
          {renderUserVoice()}
        </Grid>
        <Grid item xs="auto">
          <LogoWidget isHeader isMenuItem />
        </Grid>
        <Grid item xs="auto">
          <LanguageSelectorWidget iconView={'header'} />
        </Grid>
        {!customization.isEmbeddingPlatform && (
          <Grid item xs="auto">
            <Tooltip title={accountName} placement="top" arrow>
              <Typography variant="body1" color={theme.palette.text.primary} noWrap maxWidth={98}>
                {accountName}
              </Typography>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs="auto">
          <AccountMenuWidget />
        </Grid>
      </Grid>
    );
  };

  const renderSubscriptionStatusWidget = () => {
    if (!customization.showSubscription) {
      return null;
    }
    return <SubscriptionStatusWidget />;
  };

  if (!customization.showHeader) {
    return null;
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.header.default,
        mb: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Container sx={{ margin: 0 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item container xs={'auto'} alignSelf={'end'}>
            <LogoWidget isHeader={true} />
          </Grid>
          <Grid item xs={'auto'}>
            {renderSubscriptionStatusWidget()}
          </Grid>
          <Grid item xs={'auto'} justifyContent="flex-start" textAlign="left">
            <MarketDetailsWidget />
          </Grid>
          <Grid item xs="auto">
            {renderMenuItems()}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
