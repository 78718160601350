import {
  expandedQuoteAtomFamily,
  expandedQuotesUpdater,
  fetchQuotes,
  isHubInitiatedState,
  subscribeToQuotes,
  unsubscribeFromQuotes,
} from '@op/shared/src/states';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getQuotesAndSentiments } from '@op/shared/src/services';
import { sentimentsUpdater } from '@op/shared/src/states/sentiment-hub-states';
import { SentimentModel } from './sentiment-model';

export const useHubExpandedQuote = (symbol: string) => {
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(symbol));
  const setExpandedQuotes = useSetRecoilState(expandedQuotesUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  useEffect(() => {
    if (!isHubInitiated || !symbol) {
      return;
    }
    (async () => {
      if (!expandedQuote) {
        await fetchQuote();
      }
      await subscribeToQuotes([symbol]);
    })();
    //Clearing function . TODO: use with mode.
    // return () => {
    //   (async () => {
    //     await unsubscribeFromQuotes([symbol]);
    //   })();
    // };
  }, [isHubInitiated, symbol]);

  const fetchQuote = async () => {
    // console.log('symbol', symbol);
    // const fetchedQuotes = await fetchQuotes([symbol]);
    // if (!fetchedQuotes || fetchedQuotes.length === 0) {
    //   return;
    // }
    // console.log('fetched', fetchedQuotes);
    // setExpandedQuotes(fetchedQuotes);
    // From here
    const quotesAndSentiments = await getQuotesAndSentiments([symbol]);
    if (!quotesAndSentiments) {
      return;
    }
    setExpandedQuotes(quotesAndSentiments.quotes);
    setSentiments(quotesAndSentiments.sentiments.map((s) => SentimentModel.fromData(s)));
  };
};
