import { Grid } from '@mui/material';
import { configurationState, customizationState, isUserSettingUpdatedState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GuideItem } from '../side-menu';
import { MarketIndexesWidget } from './market-indexes-widget';
import { MarketWorkTimeDetailsWidget } from './market-work-time-details-widget';
import { SentimentsTableWidget } from './sentiments-table-widget';

export const MarketDetailsWidget: React.FC = () => {
  const customization = useRecoilValue(customizationState);
  const configuration = useRecoilValue(configurationState);
  const isUserSettingUpdated = useRecoilValue(isUserSettingUpdatedState);
  const [symbol, setSymbol] = useState<string>('');

  useEffect(() => {
    if (!configuration || !configuration.marketIndexQuoteSymbol) {
      return;
    }
    const symbol = configuration.marketIndexQuoteSymbol;
    setSymbol(symbol);
  }, [configuration, isUserSettingUpdated]);

  return (
    <Grid container justifyContent="center" alignItems="center" columnSpacing={1.25}>
      {customization.showMarketToneInHeader && (
        <>
          <Grid item xs="auto">
            <MarketIndexesWidget symbol={symbol} />
          </Grid>
          <Grid item xs="auto">
            <GuideItem selector=".marketTone_helpPinPlaceholder" />
            <SentimentsTableWidget symbol={symbol} />
          </Grid>
        </>
      )}
      <Grid item xs="auto">
        <MarketWorkTimeDetailsWidget />
      </Grid>
    </Grid>
  );
};
