import { Card, Grid, IconButton, styled, useTheme } from '@mui/material';
import { IData, Quote, WatchList, WatchLists, isUS } from '@op/shared/src/models';
import { EventType, WatchListsOperationTypes, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { WatchlistService } from '@op/shared/src/services';
import {
  deletedWatchlistItemState,
  howWhyRanksDataState,
  isDataLoadingState,
  selectedWatchListState,
  strategyShareDataState,
  subViewState,
  tradeSimulatorOperationTypeState,
  viewState,
  watchListCrudOperationState,
  watchListsState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ExpandedQuoteHubWidget, IconComponent, TechnicalScoreWidget, TrendingWidget } from '../common';
import { CompanyTitleWidget } from '../portfolio/company-title-widget';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { useNavigateSearch } from '../states/use-navigate-search';
import { GridHoverBorder } from '../styled';
import { isTSTradeTicketPreserved } from '../tradestation/states/ts-ticket-states';
import { FirstRow } from './row-first';
import { SliderRowView } from './slider-row-view';
import { useFallbackSymbol } from '../states/use-fallback-symbol';

type WatchListsRowWidgetProps = IData<Quote>;

type OPWatchListStyledHoverCardProp = {
  selected?: boolean;
  onSymbolSelected?: () => void;
};

export const OPWatchListStyledHoverCard = styled(Card)<OPWatchListStyledHoverCardProp>(({ selected, theme }) => ({
  boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
  borderRadius: theme.shape.borderRadius,
  border: selected ? `1.5px solid ${theme.palette.primary.main}` : `1.5px solid transparent`,
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
    border: `1.5px solid ${theme.palette.primary.main}`,
    // backgroundColor: theme.palette.info.dark,
  },
}));

export const WatchListRowWidget: React.FC<WatchListsRowWidgetProps> = ({ data }: WatchListsRowWidgetProps) => {
  const fetchAndSetData = useFetchAndSetData();
  const navigateSearch = useNavigateSearch();
  const [searchParams] = useSearchParams();
  const howWhySymbol = useRecoilValue(howWhyRanksDataState);
  const watchListCrudOperation = useRecoilValue(watchListCrudOperationState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setSubView = useSetRecoilState(subViewState);
  const setNotifications = useSetRecoilState(notificationsState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);

  const [view, setView] = useRecoilState(viewState);
  const [watchLists, setWatchLists] = useRecoilState(watchListsState);
  const [watchList, setWatchList] = useRecoilState<WatchList | undefined>(selectedWatchListState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const [deletedWatchListItem, setDeletedWatchListItem] = useRecoilState(deletedWatchlistItemState);
  const theme = useTheme();
  const navigate = useNavigate();
  const [slideSelect, setSlideSelect] = useState(false);

  const signals = [{ date: '', name: 'TechnicalRank', symbol: data.symbol, value: data.technicalRank }];
  const isSymbolAvailable = howWhySymbol?.symbol?.toUpperCase() === data?.symbol.toUpperCase();
  const showDeleteIconIfPossible = watchListCrudOperation === WatchListsOperationTypes.EDIT;
  const [isProcess, setIsProcess] = useState(false);
  const getFallbackSymbol = useFallbackSymbol();

  const onSymbolSelected = async (symbol?: string, subView?: string) => {
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    resetStrategyShareData();
    const fallbackSymbol = getFallbackSymbol(symbol || data.symbol);
    const isSuccessful = await fetchAndSetData(fallbackSymbol, undefined);
    if (!isSuccessful) {
      setIsDataLoading(false);
      return;
    }
    setSubView(isTSTradeTicketPreserved() || subView ? 'tradeTicket' : undefined);
    setView(view);
    setSimulatorOperation(
      view === 'income' ? tradeSimulatorOperationType.OptionsGrid : tradeSimulatorOperationType.PLSimulator,
    );
    ApplicationContext.userActivityHub?.logActivity(
      'watchListSelectSymbol',
      'watchListSelectSymbol',
      data.symbol,
      'click',
      data.symbol,
    );
    // navigateSearch({ symbol: symbol || data.symbol });
    setIsDataLoading(false);
  };

  const processUrl = async () => {
    const symbol = searchParams.get('symbol');
    const subView = searchParams.get('subView');
    if (!symbol) {
      return;
    }
    await onSymbolSelected(symbol, subView);
  };

  useEffect(() => {
    if (isProcess || isDataLoading) {
      return;
    }
    (async () => {
      setIsProcess(true);
      await processUrl();
    })();
  }, []);

  const onSymbolDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!watchList) {
      return;
    }
    e.stopPropagation();
    let deletedSymbols: string[] = [...deletedWatchListItem];
    const newWatchList = WatchList.fromSelf(watchList);
    newWatchList.quotes = newWatchList.quotes.filter((item) => item.symbol !== data.symbol);
    deletedSymbols.push(data.symbol);
    setDeletedWatchListItem(deletedSymbols);
    setWatchList(newWatchList);
    ApplicationContext.userActivityHub?.logActivity(
      'watchListRemoveSymbol',
      'watchListRemoveSymbol',
      data.symbol as string,
      EventType.Click,
      data.symbol as string,
    );
  };

  const pinUnpinWatchListSymbol = async () => {
    if (!watchList || !watchLists) {
      return;
    }
    try {
      const response: any = await WatchlistService.instance.pinWatchList(watchList.id, data.symbol, !data.isPinned);
      if (!response || !response.data) {
        setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
        return;
      }
      const clonedWatchlist = WatchLists.fromSelf(watchLists);
      const watchListData = clonedWatchlist.data.map((w) => {
        if (w.id === watchList.id) {
          w.quotes.map((q) => {
            if (q.symbol === data.symbol) {
              q.isPinned = !data.isPinned;
            }
            return q;
          });
        }
        return w;
      });
      clonedWatchlist.data = watchListData;
      setWatchLists(clonedWatchlist);
      setSlideSelect(false);
      setNotifications([{ type: 'success', content: 'Operation successful' }]);
      return;
    } catch (error) {
      setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
    }
  };

  const redirectToTradeIdea = () => {
    navigate('/');
    onSymbolSelected();
  };

  const renderEditView = () => {
    return (
      <Grid container>
        <Grid item xs={10} py={1} container justifyContent="space-between" onClick={() => onSymbolSelected()}>
          <Grid item xs={12} container justifyContent="space-between">
            <FirstRow quote={data} onClickPinUnpin={pinUnpinWatchListSymbol} onClickTradeIdea={redirectToTradeIdea} />
          </Grid>
          <Grid item xs={12} justifyContent="space-between" container>
            <Grid item xs={8}>
              <ExpandedQuoteHubWidget data={data.symbol} />
            </Grid>
            <Grid item xs={4} justifyContent="right">
              <TechnicalScoreWidget data={signals} isOnlyScore />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CompanyTitleWidget symbol={data.symbol} hasQuotes={true} companyName={data.company} />
          </Grid>
        </Grid>
        <Grid item container xs={2} justifyContent="right">
          <Grid item xs={1} style={{ backgroundColor: '#999999' }}></Grid>
          <Grid
            item
            xs={10}
            justifyContent="center"
            display="grid"
            alignItems="center"
            style={{ backgroundColor: theme.palette.info.dark }}>
            <IconButton onClick={async (e) => await onSymbolDelete(e)} size="small">
              <IconComponent name={'deleteIcon'} stroke={'#999999'} hoverColor="red" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderView = () => {
    return (
      <Grid
        container
        style={{ backgroundColor: isSymbolAvailable ? theme.palette.primary.main : theme.palette.symbolCard.default }}>
        <GridHoverBorder
          item
          xs={isSymbolAvailable ? 11.5 : 12}
          container
          onClick={() => data.symbol && onSymbolSelected()}
          isselected={isSymbolAvailable}
          sx={{ backgroundColor: theme.palette.symbolCard.default, py: 0.5, pr: 0.5 }}>
          <Grid item xs={12} container justifyContent="space-between">
            <FirstRow quote={data} onClickPinUnpin={pinUnpinWatchListSymbol} onClickTradeIdea={redirectToTradeIdea} />
          </Grid>
          <Grid item xs={12} justifyContent="space-between" container>
            <Grid item xs={8}>
              <ExpandedQuoteHubWidget data={data.symbol} />
            </Grid>
            <Grid item justifyContent="right">
              <TechnicalScoreWidget data={signals} isOnlyScore />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CompanyTitleWidget symbol={data.symbol} hasQuotes={true} companyName={data.company} />
          </Grid>
        </GridHoverBorder>
        {isSymbolAvailable && (
          <Grid
            xs={0.5}
            textAlign="left"
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              isUS() && setSlideSelect(!slideSelect);
            }}>
            <IconButton size="small">
              <IconComponent name="sliderOpenIcon" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <OPWatchListStyledHoverCard
      selected={isSymbolAvailable}
      sx={{
        width: '100%',
      }}>
      <TrendingWidget symbol={data.symbol} sentiment={data.sentiment}>
        {showDeleteIconIfPossible ? (
          renderEditView()
        ) : slideSelect ? (
          <SliderRowView
            data={data}
            showDeleteIconIfPossible={showDeleteIconIfPossible}
            onSymbolSelected={onSymbolSelected}
            setSlideSelect={() => setSlideSelect(!slideSelect)}
          />
        ) : (
          renderView()
        )}
      </TrendingWidget>
    </OPWatchListStyledHoverCard>
  );
};
