import { Grid, Theme, useTheme } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { accountState } from '@op/shared/src/states/account-state';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { SentimentShortTermWidget, SentimentLongTermWidget } from '../common';

interface Props {
  symbol: string;
}

export const SentimentsTableWidget: React.FC<Props> = ({ symbol }) => {
  const account = useRecoilValue(accountState);
  const theme = useTheme<Theme>();

  if (!account) {
    return null;
  }

  return (
    <Grid container style={{ color: theme.palette.text.primary }}>
      <Grid item>
        <div>
          <SentimentShortTermWidget symbol={symbol} />
        </div>
        <div>
          <SentimentLongTermWidget symbol={symbol} />
        </div>
      </Grid>
    </Grid>
  );
};
