import {
  accountState,
  customizationState,
  subscriptionCurrentScreenState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ProfileCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { CancelSubscriptionWidget } from './cancel-subscription';
import { ChangeSubscriptionWidget } from './change-subscription';
import { SubscriptionCurrentWidget } from './subscription-current-widget';
import { CurrentSubscriptionSkeletonWidget } from './subscription-skeleton-widget';

export const SubscriptionWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const customization = useRecoilValue(customizationState);
  const subscriptionCurrentScreen = useRecoilValue(subscriptionCurrentScreenState);
  const userSubscriptionFeatures = useRecoilValue(userSubscriptionFeaturesState);
  const subscriptionPlanPrices = useRecoilState(subscriptionPlanPricesModelState);

  if (!account || !customization) {
    return <></>;
  }

  const renderContent = () => {
    switch (subscriptionCurrentScreen) {
      case 'CurrentSubscription':
        return <SubscriptionCurrentWidget />;
      case 'ChangeSubscription':
        return <ChangeSubscriptionWidget />;
      case 'Cancelsubscription':
        return <CancelSubscriptionWidget />;
      default:
        return <SubscriptionCurrentWidget />;
    }
  };

  if (!userSubscriptionFeatures || !subscriptionPlanPrices) {
    return <CurrentSubscriptionSkeletonWidget />;
  }

  return <ProfileCardWidget isStepper title={t('subscriptions.subscription')} content={renderContent()} />;
};
