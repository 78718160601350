import { Grid, Link, Typography, useTheme } from '@mui/material';
import PlayStoreLink from '@op/shared/assets/images/googlePlayImage.svg';
import PlayStoreQR from '@op/shared/assets/images/playStoreLink.svg';
import AppStoreLink from '@op/shared/assets/images/appStoreImage.svg';
import AppStoreQR from '@op/shared/assets/images/appStoreLink.svg';
import React, { useContext, useState } from 'react';
import { OPDialogWidget } from '../common';
import { OPBoldTypograpghy } from '../styled';
import LocalizationContext from '../react-i18next/localization-context';

export const MobileAppLinkWidget: React.FC = () => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const [openDialog, setopenDialog] = useState(false);
  const [appleStoreQR, setAppleStoreQR] = useState(false);

  const handleClose = () => {
    setopenDialog(false);
    setAppleStoreQR(false);
  };

  const handleScanQRClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setopenDialog(true);
  };

  const renderPlayStoreLink = () => {
    return (
      <Grid item container xs={12} justifyContent={'center'} textAlign={'center'}>
        <Grid
          item
          xs={7}
          container
          justifyContent={'center'}
          sx={{
            ':hover': {
              backgroundColor: theme.palette.primary.main,
              '& .scanText': { color: theme.palette.timeFrame.contrastText },
            },
            backgroundColor: theme.palette.divider,
            borderRadius: 1,
          }}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.optionsplay.app"
            target="_blank"
            rel="noreferrer">
            <img src={PlayStoreLink} alt="" style={{ height: '35px', width: '100%', borderRadius: '4px' }} />
          </Link>
          <div onClick={handleScanQRClick} style={{ width: '100%', cursor: 'pointer' }}>
            <OPBoldTypograpghy className="scanText" lineHeight={1} sx={{ paddingBottom: 1 }}>
              {t('subscriptions.showQRCode')}
            </OPBoldTypograpghy>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderAppStoreLink = () => {
    return (
      <Grid item container xs={12} justifyContent={'center'} textAlign={'center'}>
        <Grid
          item
          xs={7}
          container
          justifyContent={'center'}
          sx={{
            ':hover': {
              backgroundColor: theme.palette.primary.main,
              '& .scanText': { color: theme.palette.timeFrame.contrastText },
            },
            backgroundColor: theme.palette.divider,
            borderRadius: 1,
          }}>
          <Link href="https://apps.apple.com/us/app/optionsplay/id1589738059" target="_blank" rel="noreferrer">
            <img src={AppStoreLink} alt="" style={{ height: '35px', width: '100%', borderRadius: '4px' }} />
          </Link>
          <div
            onClick={(e) => {
              handleScanQRClick(e);
              setAppleStoreQR(true);
            }}
            style={{ width: '100%', cursor: 'pointer' }}>
            <OPBoldTypograpghy className="scanText" lineHeight={1} sx={{ paddingBottom: 1 }}>
              {t('subscriptions.showQRCode')}
            </OPBoldTypograpghy>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = () => {
    return (
      <Grid container alignItems={'center'} mt={1} sx={{ width: '550px' }}>
        <Grid item container xs={8} justifyContent={'center'} textAlign={'center'}>
          {appleStoreQR ? (
            <img src={AppStoreQR} alt="" style={{ height: '225px', width: '225px' }} />
          ) : (
            <img src={PlayStoreQR} alt="" style={{ height: '225px', width: '225px' }} />
          )}
          <Grid item xs={8} container textAlign={'left'} py={1}>
            <Typography
              variant="caption"
              style={{
                fontSize: '10px',
              }}>
              {t('subscriptions.scannerText')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={4} sx={{ backgroundColor: theme.palette.grey[200], padding: 4 }}>
          {appleStoreQR ? (
            <Grid item xs={12}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.optionsplay.app"
                target="_blank"
                rel="noreferrer">
                <img src={AppStoreLink} alt="" style={{ height: '35px', borderRadius: '4px' }} />
              </Link>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.optionsplay.app"
                target="_blank"
                rel="noreferrer">
                <img src={PlayStoreLink} alt="" style={{ height: '35px', borderRadius: '4px' }} />
              </Link>
            </Grid>
          )}
          <Grid item xs={12} py={1}>
            <Typography>{t('subscriptions.downloadAppText')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDialogTitle = () => {
    const dialogTitleText = `${t('subscriptions.mobile')} ${appleStoreQR ? 'IOS' : 'Android'} ${t(
      'subscriptions.appLink',
    )}`;
    return (
      <Grid item xs={12}>
        <OPBoldTypograpghy variant="subtitle1" textAlign={'left'}>
          {dialogTitleText}
        </OPBoldTypograpghy>
      </Grid>
    );
  };

  return (
    <Grid container xs={12} rowSpacing={1}>
      <Grid item xs={12} textAlign={'center'}>
        <OPBoldTypograpghy>{t('subscriptions.mobileAppAvailable')}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12}>
        {renderPlayStoreLink()}
      </Grid>
      <Grid item xs={12}>
        {renderAppStoreLink()}
      </Grid>
      <Grid item xs={12}>
        <OPDialogWidget
          open={openDialog}
          dialogTitleStyle={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}
          onHandleClose={handleClose}
          dialogContent={renderDialogContent()}
          title={renderDialogTitle()}
          dialogAction={undefined}
          dialogdividers
        />
      </Grid>
    </Grid>
  );
};
