import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { IGlobalization, cloneDeep } from '@op/shared/src/models';
import { PowerhouseProService, ProfileDetailsService } from '@op/shared/src/services';
import {
  configurationState,
  globalizationState,
  isUserSettingUpdatedState,
  userSettingState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ThemeSettingWidget, TimeoutSettingWidget } from '.';
import { LanguageSelectorWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider } from '../styled/options-play-components';
import { RegionSettingWidget } from './region-settings';

export const AccountSetting: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const globalization = useRecoilValue(globalizationState);
  const [localeItems, setLocaleItems] = useState<IGlobalization[] | undefined>();
  const [setting, setSetting] = useRecoilState(userSettingState);
  const { t } = React.useContext(LocalizationContext);
  const [theme, setTheme] = useState('');
  const [isUserSettingUpdated, setIsUserSettingUpdated] = useRecoilState(isUserSettingUpdatedState);
  const [configuration, setConfiguration] = useRecoilState(configurationState);

  useEffect(() => {
    if (!globalization || !globalization.active) {
      return;
    }
    setLocaleItems(globalization.items);
  }, [globalization]);

  useEffect(() => {
    if (!setting) {
      return;
    }
    setTheme(setting.theme);
  }, [setting]);

  if (!globalization || !localeItems || !setting) {
    return <></>;
  }

  const onUpdate = async (): Promise<void> => {
    //TODO: ProfileDetailsService should be removed. they can have separate functions.
    const updates = cloneDeep(setting);
    updates.theme = theme;
    const response = await ProfileDetailsService.instance.AccountSettings(updates);
    if (response.hasErrors || !response.data) {
      setNotifications([{ type: 'error', content: 'Unexpected error happened. Please try again.' }]);
      return;
    }
    setNotifications([{ type: 'success', content: 'Your settings have been updated' }]);
    setSetting(response.data);
    const configurationResponse = await PowerhouseProService.instance.Configuration();
    const configuration = configurationResponse.data;
    if (!configuration) {
      setNotifications([{ type: 'error', content: 'Unexpected error happened. Please try again.' }]);
      return;
    }
    setConfiguration(configuration);
    setIsUserSettingUpdated(isUserSettingUpdated + 1);
  };

  return (
    <Card>
      <CardHeader title={t('profile.accountSettings.titles.setYourLanguage')} sx={{ pb: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <Grid container spacing={2}>
          <LanguageSelectorWidget iconView={'profile'} />
        </Grid>
      </CardContent>
      <CardHeader title={t('profile.accountSettings.titles.setYourTimeOutPeriod')} sx={{ pb: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <TimeoutSettingWidget />
      </CardContent>
      <CardHeader title={t('profile.accountSettings.titles.setYourTheme')} sx={{ pb: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <ThemeSettingWidget />
      </CardContent>
      <CardHeader title={'Set your region'} sx={{ pb: 0 }} />
      <OptionsPlayDivider />
      <CardContent>
        <RegionSettingWidget />
      </CardContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={onUpdate} id="pw-update" color="primary" variant="contained">
          <Typography variant="button">{t('common.buttons.update')}</Typography>
        </Button>
      </Box>
    </Card>
  );
};
