import lodash from 'lodash';
import {
  DefaultRawReturnValues,
  Filters,
  ISignal,
  LinkedPortfolio,
  LinkedWatchlist,
  SortingType,
  Sorts,
  orderByAscending,
  orderByDescending,
} from '..';
import NumberFormatHelper from '../how/number-format-helper';
import { ICollection } from '../what/collection';

export interface IShortPutReport {
  symbol: string;
  companyName: string;
  action: string;
  expiry: string;
  daysToExpiry: number;
  strikePrice: number;
  midPrice: number;
  bidPrice: number;
  askPrice: number;
  impliedVolatilityRank: string;
  earningsDate: string;
  earningsFlag: string;
  stockPrice: number;
  rawReturn: string;
  annualizedReturn: string;
  distanceToStrike: string;
  occSymbol: string;
  expiryThreshold: number;
  deltaThreshold: number;
  liquidityRank: number;
  signals: ISignal[];
  portfolios: LinkedPortfolio[];
  watchlists: LinkedWatchlist[];
  url: string;
  exchangeCode: string;
}

export class ShortPuts implements ICollection<IShortPutReport> {
  data: IShortPutReport[] = [];
  shortPuts: IShortPutReport[] = [];
  created = '';
  pricesAt = '';

  public constructor(response: ShortPuts) {
    this.data = response.shortPuts;
    this.shortPuts = response.shortPuts;
    this.created = response.created;
    this.pricesAt = response.pricesAt;
  }

  public static fromSelf = (shortPuts: ShortPuts): ShortPuts => {
    if (!shortPuts) {
      throw Error('shortPut is null or undefined');
    }
    const model = new ShortPuts(shortPuts);
    return model;
  };

  public get shortPutCount(): number {
    return this.shortPuts.length;
  }

  public get totalPortfolios(): number {
    return this.shortPuts
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
  }

  public get totalWatchlists(): number {
    return this.shortPuts
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
  }

  public sort = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    const shortPuts = this.sortByRawReturn(sorts)
      .sortBySymbol(sorts)
      .sortByCompanyName(sorts)
      .sortByPremium(sorts)
      .sortByIVRank(sorts)
      .sortByEarningDate(sorts);
    return shortPuts;
  };

  public filter = (filters: Filters): ShortPuts => {
    if (!filters) {
      return this;
    }
    let shortPuts = this.filterByHasEarnings(filters)
      .filterByIVRank(filters)
      .filterByLiquidityRating(filters)
      .filterByRawReturn(filters)
      .filterByPortfolio(filters)
      .filterByWatchList(filters);
    return shortPuts;
  };

  private sortByRawReturn = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'rawReturn') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByAscending(this.shortPuts, 'rawReturn');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByDescending(this.shortPuts, 'rawReturn');
      }
    }
    return this;
  };

  private sortBySymbol = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'symbol') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByAscending(this.shortPuts, 'symbol');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByDescending(this.shortPuts, 'symbol');
      }
    }
    return this;
  };

  private sortByCompanyName = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByAscending(this.shortPuts, 'companyName');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByDescending(this.shortPuts, 'companyName');
      }
    }
    return this;
  };

  private sortByPremium = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'premium') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByAscending(this.shortPuts, 'midPrice');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.shortPuts = orderByDescending(this.shortPuts, 'midPrice');
      }
    }
    return this;
  };

  private sortByIVRank = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'ivRank') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.shortPuts = lodash.orderBy(
          this.shortPuts,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['asc'],
        );
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.shortPuts = lodash.orderBy(
          this.shortPuts,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['desc'],
        );
      }
    }
    return this;
  };

  private sortByEarningDate = (sorts: Sorts): ShortPuts => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'earningDate') {
        continue;
      }
      const clone = this.shortPuts.slice();
      let yesHasEarnings = clone.filter((e) => e.earningsDate);
      const noHasEarnings = clone.filter((e) => !e.earningsDate);
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(yesHasEarnings, [(e) => new Date(e.earningsDate)], ['asc']);
        this.shortPuts = [...yesHasEarnings, ...noHasEarnings];
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(yesHasEarnings, [(e) => new Date(e.earningsDate)], ['desc']);
        this.shortPuts = [...yesHasEarnings, ...noHasEarnings];
      }
    }
    return this;
  };

  public filterByHasEarnings = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'hasEarnings') {
        continue;
      }
      if (filter.value.toString().toLowerCase() === '') {
        shortPuts.shortPuts = [];
        shortPuts = new ShortPuts(shortPuts);
        continue;
      }

      if (filter.value.length === 2) {
        continue;
      }
      for (const f of filter.value) {
        if (f.toString().toUpperCase() === 'Y') {
          const newShortPuts = this.data.filter((t) => t.earningsFlag === 'Y');
          shortPuts.shortPuts = newShortPuts;
          shortPuts = new ShortPuts(shortPuts);
        }
        if (f.toString().toUpperCase() === 'N') {
          const newShortPuts = this.data.filter((t) => t.earningsFlag === 'N');
          shortPuts.shortPuts = newShortPuts;
          shortPuts = new ShortPuts(shortPuts);
        }
      }
    }
    return shortPuts;
  };

  public filterByIVRank = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'ivRank') {
        continue;
      }
      if (filter.value.toString().toLowerCase() === '') {
        shortPuts.shortPuts = [];
        shortPuts = new ShortPuts(shortPuts);
        continue;
      }
      if (filter.value.length === 2) {
        continue;
      }
      for (const f of filter.value) {
        if (f.toString().toLowerCase() === '<50') {
          const newShortPuts = this.shortPuts.filter((t) => Number(t.impliedVolatilityRank.replace('%', '')) < 50);
          shortPuts.shortPuts = newShortPuts;
          shortPuts = new ShortPuts(shortPuts);
        }
        if (f.toString().toLowerCase() === '>=50') {
          const newShortPuts = this.shortPuts.filter((t) => Number(t.impliedVolatilityRank.replace('%', '')) >= 50);
          shortPuts.shortPuts = newShortPuts;
          shortPuts = new ShortPuts(shortPuts);
        }
      }
    }
    return shortPuts;
  };

  public filterByLiquidityRating = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'liquidity') {
        continue;
      }
      if (filter.value.toString().toLowerCase() === '') {
        shortPuts.shortPuts = [];
        shortPuts = new ShortPuts(shortPuts);
        continue;
      }
      if (filter.value.length === 3) {
        continue;
      }
      let shortPutReports: IShortPutReport[] = [];
      for (const f of filter.value) {
        if (f.toString().toLowerCase() === '1') {
          const newShortPuts = this.shortPuts.filter((t) => t.liquidityRank === 1);
          shortPutReports.push(...newShortPuts);
        }
        if (f.toString().toLowerCase() === '2') {
          const newShortPuts = this.shortPuts.filter((t) => t.liquidityRank === 2);
          shortPutReports.push(...newShortPuts);
        }
        if (f.toString().toLowerCase() === '3') {
          const newShortPuts = this.shortPuts.filter((t) => t.liquidityRank === 3);
          shortPutReports.push(...newShortPuts);
        }
        shortPuts.shortPuts = shortPutReports;
        shortPuts = new ShortPuts(shortPuts);
      }
    }
    return shortPuts;
  };

  public filterByRawReturn = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'rawReturn') {
        continue;
      }
      if (filter.value.toString().toLowerCase() === '') {
        shortPuts.shortPuts = [];
        shortPuts = new ShortPuts(shortPuts);
        continue;
      }
      if (filter.value.length === 4) {
        continue;
      }
      let shortPutReports: IShortPutReport[] = [];
      for (const f of filter.value) {
        if (f.toString().toLowerCase() === DefaultRawReturnValues[0]) {
          const newShortPuts = this.shortPuts.filter((t) => NumberFormatHelper.roundPercentageValue(t.rawReturn) < 5);
          shortPutReports.push(...newShortPuts);
        }
        if (f.toString().toLowerCase() === DefaultRawReturnValues[1]) {
          const newShortPuts = this.shortPuts.filter(
            (t) =>
              NumberFormatHelper.roundPercentageValue(t.rawReturn) >= 5 &&
              NumberFormatHelper.roundPercentageValue(t.rawReturn) < 15,
          );
          shortPutReports.push(...newShortPuts);
        }
        if (f.toString().toLowerCase() === DefaultRawReturnValues[2]) {
          const newShortPuts = this.shortPuts.filter(
            (t) =>
              NumberFormatHelper.roundPercentageValue(t.rawReturn) >= 15 &&
              NumberFormatHelper.roundPercentageValue(t.rawReturn) < 30,
          );
          shortPutReports.push(...newShortPuts);
        }
        if (f.toString().toLowerCase() === DefaultRawReturnValues[3]) {
          const newShortPuts = this.shortPuts.filter((t) => NumberFormatHelper.roundPercentageValue(t.rawReturn) >= 30);
          shortPutReports.push(...newShortPuts);
        }
        shortPuts.shortPuts = shortPutReports;
        shortPuts = new ShortPuts(shortPuts);
      }
    }
    return shortPuts;
  };

  public filterByPortfolio = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'portfolio') {
        continue;
      }
      if (filter.value.length === 0) {
        continue;
      }

      const newShortPuts = this.shortPuts.filter((t) => filter.value.includes(t.symbol));
      shortPuts.shortPuts = newShortPuts;
      shortPuts = new ShortPuts(shortPuts);
    }
    return shortPuts;
  };

  public filterByWatchList = (filters: Filters): ShortPuts => {
    if (!filters) {
      return ShortPuts.fromSelf(this);
    }
    let shortPuts = ShortPuts.fromSelf(this);
    for (const filter of filters.data) {
      if (filter.name !== 'watchlist') {
        continue;
      }
      if (filter.value.length === 0) {
        continue;
      }

      const newShortPuts = this.shortPuts.filter((t) => filter.value.includes(t.symbol));
      shortPuts.shortPuts = newShortPuts;
      shortPuts = new ShortPuts(shortPuts);
    }
    return shortPuts;
  };
}
