import { Box, Button, Grid, Link, SxProps, Theme, Typography, useTheme } from '@mui/material';
import AuthroizeLogo from '@op/shared/assets/images/authroizelogo.png';
import React, { ReactNode, useContext } from 'react';
import LocalizationContext from '../react-i18next/localization-context';

interface Props {
  sx?: SxProps<Theme>;
}

export const SubscriptionFooterWidget: React.FC<Props> = ({ sx }: Props) => {
  const theme = useTheme();
  const email = `customer.support@op.com`;
  const { t } = useContext(LocalizationContext);

  const renderEmail = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Grid container sx={sx} justifyContent="space-between">
      <Grid
        item
        xs={5.9}
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: 1,
          p: 1,
          backgroundColor: theme.palette.greyShades.light,
        }}>
        <Typography sx={{ fontSize: '10px !important', fontWeight: 'bold', mr: 2 }}>
          {t('subscriptions.securedBy')}
        </Typography>
        <AuthorizeSymbolWidget />
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{
          borderRadius: 1,
          backgroundColor: theme.palette.greyShades.light,
        }}>
        <Typography style={{ fontSize: '12px', fontWeight: 600 }}>{t('subscriptions.contactUs')}:</Typography>
        <Link href="mailto:support@optionsplay.com" target="_blank" rel="noreferrer">
          <Button
            style={{ fontSize: '12px' }}
            onClick={renderEmail}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              },
              '&.MuiButton-text': {
                textTransform: 'none',
              },
            }}>
            support@optionsplay.com
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export const SubscriptionHeaderWidget: React.FC<{ label: ReactNode }> = ({ label }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Grid container justifyContent="space-between" alignItems={'center'}>
      <Grid item xs={9.5}>
        {label}
      </Grid>
      <Grid item xs={2.5} container justifyContent="flex-end" alignItems="baseline">
        <Box display="flex" alignItems="center" textAlign="center">
          <Typography sx={{ fontSize: '10px !important', fontWeight: 400, mr: 1 }}>
            {t('subscriptions.securedBy')}
          </Typography>
          <AuthorizeSymbolWidget />
        </Box>
      </Grid>
    </Grid>
  );
};

export const AuthorizeSymbolWidget: React.FC = () => {
  const AUTHORIZE_NET_SEAL_CUSTOMSER_ID = 'ac1e3f45-11d7-4988-b40e-c1c20eebb4c3';
  const pathname = window.location.href;
  return (
    <Box style={{ textAlign: 'center' }}>
      <Link
        href={`https://verify.authorize.net/anetseal/?pid=${AUTHORIZE_NET_SEAL_CUSTOMSER_ID}&amp;rurl=${pathname}`}
        target="_blank"
        rel="noreferrer">
        <Box
          component="img"
          src={AuthroizeLogo}
          alt="Logo"
          sx={{
            display: 'block',
            mx: 'auto',
            width: '65px',
            height: '52px',
          }}></Box>
      </Link>
    </Box>
  );
};
