import ApplicationContext from '@op/shared/src/models/how/application-context';
import { atom, selector } from 'recoil';
import { guardRecoilDefaultValue } from '..';
import { IConfiguration, IUserSetting, cloneDeep } from '../../models';

export const isConfigurationUpdatedState = atom<number>({
  key: 'isConfigurationState',
  default: 0,
});

export const configMinWidth = atom<string>({
  key: 'configMinWidthKey',
  default: '1366px',
});

export const configurationState = selector<IConfiguration | undefined>({
  key: 'configurationStateKey',
  get: ({ get }) => {
    const configuration = get(isConfigurationUpdatedState);
    if (configuration === 0) {
      return undefined;
    }
    return ApplicationContext.configuration;
  },
  set: ({ set, get }, newConfiguration) => {
    if (guardRecoilDefaultValue(newConfiguration)) {
      throw new Error('Its default value');
    }
    if (!newConfiguration) {
      console.error('Configuration is undefined');
      return;
    }
    ApplicationContext.configuration = newConfiguration;
    const existing = get(isConfigurationUpdatedState);
    set(isConfigurationUpdatedState, existing + 1);
  },
});

export const configurationPortfolioAlertResourcesState = selector({
  key: 'configurationPortfolioAlertResourcesStateKey',
  get: ({ get }) => {
    const configuration = get(configurationState);
    if (!configuration) {
      return undefined;
    }
    const resources = ApplicationContext.configuration.portfolioAlertResources;
    if (resources.length === 0) {
      return undefined;
    }
    return resources;
  },
});

export const userSettingState = selector<IUserSetting | undefined>({
  key: 'userSettingStateKey',
  get: ({ get }) => {
    const configuration = get(configurationState);
    if (configuration === undefined) {
      return undefined;
    }
    return ApplicationContext.configuration.userSettings;
  },
  set: ({ set, get }, setting) => {
    if (guardRecoilDefaultValue(setting)) {
      throw new Error('Its default value');
    }
    if (!setting) {
      console.error('user setting is undefined');
      return;
    }
    const configuration = get(configurationState);
    if (!configuration) {
      throw new Error('Configuration is undefined using update');
    }
    const clonedConfiguration = cloneDeep(configuration);
    clonedConfiguration.userSettings = setting;
    ApplicationContext.configuration = clonedConfiguration;
    const existing = get(isConfigurationUpdatedState);
    set(isConfigurationUpdatedState, existing + 1);
  },
});

export const isUserSettingUpdatedState = atom<number>({
  key: 'isUserSettingUpdatedKey',
  default: 0,
});
