import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Grid, InputAdornment, MenuItem, useTheme } from '@mui/material';
import { paymentCardTypeState, paymentDetailsErrorState, subscriptionCurrentPaymentState } from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import {
  OPBoldTypograpghy,
  OPSemiBoldTypograpghy,
  OptionsPlayMaskedFormatInput,
  OptionsPlayTextField,
} from '../styled';
import { CreditCardTypeWidget, creditCardType } from './credit-card-type-widget';
import { months, years } from './data-list';

export const CardDetailsWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const [paymentDetails, setPaymentDetails] = useRecoilState(subscriptionCurrentPaymentState);
  const setCardType = useSetRecoilState(paymentCardTypeState);
  const [errors, setErrors] = useRecoilState(paymentDetailsErrorState);

  const yearslist = years();
  const theme = useTheme();

  const isCreditCardAccordianError = () => {
    return errors.cardName || errors.cardNumber || errors.cardCvv || errors.cardMonth ? true : false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'postalCode' && event.target.value.length > 10) {
      return;
    }
    setPaymentDetails({ ...paymentDetails, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handleCardChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > paymentDetails.cardNumberMaxLength) {
      return;
    }
    setPaymentDetails({ ...paymentDetails, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const renderCardInfo = () => {
    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.nameOnCard')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="cardName"
              name="cardName"
              onChange={handleChange}
              size="small"
              autoFocus
              inputProps={{ style: { textTransform: 'uppercase' } }}
              value={paymentDetails.cardName}
              helperText={errors.cardName}
              error={errors.cardName && errors.cardName !== '' ? true : false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.cardNumber')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="cardNumber"
              name="cardNumber"
              type="number"
              value={paymentDetails.cardNumber}
              onChange={handleCardChange}
              onBlur={() => {
                setCardType(creditCardType(paymentDetails.cardNumber));
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CreditCardTypeWidget cardNumber={paymentDetails.cardNumber} />
                  </InputAdornment>
                ),
              }}
              helperText={errors.cardNumber}
              error={errors.cardNumber && errors.cardNumber !== '' ? true : false}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} columnSpacing={2} justifyContent="space-between" alignItems="center">
          <Grid item container xs={7} columnSpacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
              <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.monthYear')}</OPSemiBoldTypograpghy>
            </Grid>
            <Grid item xs={7} columnSpacing={1} container>
              <Grid item xs={6}>
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  name="cardMonth"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  value={paymentDetails.cardMonth}
                  helperText={errors.cardMonth}
                  error={errors.cardMonth && paymentDetails.cardMonth === '' ? true : false}
                  variant="outlined">
                  {months.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              </Grid>
              <Grid item xs={6}>
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="cardYear"
                  fullWidth
                  onChange={handleChange}
                  value={paymentDetails.cardYear}
                  helperText={errors.cardYear}
                  error={errors.cardYear && paymentDetails.cardYear === '' ? true : false}
                  variant="outlined">
                  {yearslist.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="space-between" columnSpacing={1} alignItems="center">
            <Grid item xs={3} textAlign="right">
              <OPSemiBoldTypograpghy variant="subtitle2">CVV</OPSemiBoldTypograpghy>
            </Grid>

            <Grid item xs={8}>
              <OptionsPlayTextField
                variant="outlined"
                id="cvv"
                name="cardCvv"
                size="small"
                type="password"
                onChange={handleChange}
                value={paymentDetails.cardCvv}
                helperText={errors.cardCvv}
                error={errors.cardCvv ? true : false}
                fullWidth
                InputProps={{
                  inputComponent: OptionsPlayMaskedFormatInput as any,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Accordion defaultExpanded={true} disableGutters elevation={0} sx={{ mb: 1 }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
          sx={{
            background: isCreditCardAccordianError() ? theme.palette.error.main : theme.palette.primary.main,
            borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
            color: theme.palette.timeFrame.contrastText,
            paddingY: 0,
            minHeight: '40px',
          }}>
          <OPBoldTypograpghy>{t('subscriptions.creditCard')}</OPBoldTypograpghy>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': { padding: '10px 0px 10px 0px' },
            mb: 1,
          }}>
          <Grid item xs={12} px={2}>
            {renderCardInfo()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
