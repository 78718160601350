import { Account, TokenResponse, getThemeType } from '@op/shared/src/models';
import { AuthenticationService, PowerhouseProService, getLocales } from '@op/shared/src/services';
import {
  accessTokenKey,
  accountState,
  configurationState,
  portfoliosDataState,
  refreshTokenKey,
  themeState,
  tradesCachedState,
  watchListsCachedState,
} from '@op/shared/src/states';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';
import { useGlobalization } from './use-globalization';

export const useRefreshToken = () => {
  const setGlobalizationLanguage = useGlobalization();
  const setAccount = useSetRecoilState(accountState);
  const setActiveTheme = useSetRecoilState(themeState);
  const setConfiguration = useSetRecoilState(configurationState);
  const resetPortfoliosData = useResetRecoilState(portfoliosDataState);
  const resetWatchlists = useResetRecoilState(watchListsCachedState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);

  const refreshToken = async () => {
    let refreshToken: string | undefined;
    try {
      refreshToken = window.localStorage.getItem(refreshTokenKey) || undefined;
    } catch (e) {
      console.error(e);
    }
    ApplicationContext.refreshToken = refreshToken as string;
    if (!refreshToken) {
      return false;
    }
    const response: ResponseViewModel<TokenResponse> = await AuthenticationService.instance.refreshToken(refreshToken);
    if (response.hasErrors || response.data === null || response.data === undefined) {
      return false;
    }
    const accountData = Account.fromTokenResponse(response.data);
    localStorage.setItem(accessTokenKey, response.data.access_token);
    localStorage.setItem(refreshTokenKey, response.data.refresh_token);
    localStorage.removeItem('newEmail');
    const configurationResponse = await PowerhouseProService.instance.Configuration();
    const localeResponse = await getLocales();
    const configuration = configurationResponse.data;
    if (!configuration || !localeResponse || !localeResponse.data) {
      return false;
    }
    resetPortfoliosData();
    resetWatchlists();
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
    setGlobalizationLanguage(localeResponse, configuration, 'login');
    const theme = getThemeType(configuration.userSettings?.theme || '');
    setAccount(accountData);
    setActiveTheme(theme);
    setConfiguration(configuration);
    const fallbackRanksSymbol = configuration.isEmbeddingPlatform
      ? configuration.additionalData?.fallbackRanksSymbol
      : '';
    window.localStorage.setItem(
      'additionalData',
      JSON.stringify({
        fallbackRanksSymbol: fallbackRanksSymbol,
        shouldFallBack: configuration.isEmbeddingPlatform,
      }),
    );
    return true;
  };

  return refreshToken;
};
