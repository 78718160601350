import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Step, StepButton, Stepper, Typography, useTheme } from '@mui/material';
import { IUpdateCardBillingRequest } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { addSubcriptionSummary, changeBillingInformationV2 } from '@op/shared/src/services';
import {
  accountState,
  activeStepState,
  addSubscriptionLoadingState,
  addSubscriptionSummaryState,
  isActivePlanChangedState,
  paymentCardTypeState,
  paymentDetailsErrorState,
  reverseStepMap,
  selectedPlansState,
  selectedSubscriptionState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentPaymentState,
  subscriptionCurrentScreenState,
  subscriptionPlanPricesModelState,
  updateCardAndBillingState,
  userSubscriptionFeaturesDataState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { AddOn } from './add-on';
import { CardInfoWidget } from './card-info-widget';
import { ChangePlan } from './change-plan';
import { SubscriptionConfirmationWidget } from './subscription-confirmation';
import { SubscriptionSummary } from './subscription-summary';
import { ValidateBillingInfo, ValidateCardInfo } from './validation';

export const ChangeSubscriptionWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const cardType = useRecoilValue(paymentCardTypeState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const [paymentDetails, setPaymentDetails] = useRecoilState(subscriptionCurrentPaymentState);
  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const selectedPlans = useRecoilValue(selectedPlansState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setErrors = useSetRecoilState(paymentDetailsErrorState);
  const setAddSubscriptionLoading = useSetRecoilState(addSubscriptionLoadingState);
  const setSubscriptionSummary = useSetRecoilState(addSubscriptionSummaryState);
  const selectedSubscription = useRecoilValue(selectedSubscriptionState);
  const [updateCardAndBillingInfo, setUpdateBillingCardInfo] = useRecoilState(updateCardAndBillingState);
  const isActivePlanChanged = useRecoilValue(isActivePlanChangedState);

  // reset states
  const resetSelectedSubscription = useResetRecoilState(selectedSubscriptionState);
  const resetPaymentDetails = useResetRecoilState(subscriptionCurrentPaymentState);
  const resetUserSubscriptionFeatures = useResetRecoilState(userSubscriptionFeaturesState);
  const resetUserSubscriptionFeaturesData = useResetRecoilState(userSubscriptionFeaturesDataState);
  const resetPricingModels = useResetRecoilState(subscriptionPlanPricesModelState);

  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  const theme = useTheme();

  if (!selectedPlans) {
    return;
  }

  const { bundles, addOns } = selectedPlans;

  const steps = [
    `${t('subscriptions.planSelection')}`,
    `${t('subscriptions.addOn')}`,
    `${t('subscriptions.cardAndBillingAdd')}`,
    `${t('subscriptions.summary')}`,
  ];

  const getStep = (step: number) => {
    return reverseStepMap[step];
  };

  const logActivity = (controlType: string, controlName: string, value: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(getStep(step));
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  // API call to get subscription summary
  const handleAddSubscriptionSummary = async () => {
    const features = [...addOns, ...bundles].map((feature) => ({
      key: feature.featureKey,
      type: feature.featureType,
      plan: feature.planType,
      label: feature.featureLabel,
    }));
    const response = await addSubcriptionSummary(features);
    return response;
  };

  // update billing and card info API call
  const updateCardAndBilling = async () => {
    const updateInfoRequest: IUpdateCardBillingRequest = {
      billingAddress: {
        address: paymentDetails.address,
        city: paymentDetails.city,
        state: paymentDetails.state,
        postalCode: paymentDetails.postalCode,
        country: paymentDetails.country,
        phone: paymentDetails.phone,
      },
      creditCard: {
        cardHolder: paymentDetails.cardName,
        cardNumber: paymentDetails.cardNumber,
        cardSecurityCode: paymentDetails.cardCvv,
        expirationMonth: paymentDetails.cardMonth,
        expirationYear: Number(paymentDetails.cardYear),
      },
      usersubscriptionId: selectedSubscription.userSubscriptionId,
    };
    const response = await changeBillingInformationV2(updateInfoRequest);
    return response;
  };

  const handleNext = async () => {
    !updateCardAndBillingInfo && logActivity('BUTTON', 'paymentsChangeScreenNextButton', reverseStepMap[activeStep]);
    if (activeStep === 1) {
      const isNoPlanSelected = bundles.length + addOns.length === 0;
      if (isNoPlanSelected) {
        setNotifications([{ type: 'error', content: 'No Plan or AddOn is selected.' }]);
        return;
      }
    }
    if (activeStep === 2 || updateCardAndBillingInfo) {
      const errors = ValidateCardInfo(paymentDetails, cardType);
      const billingErrors = ValidateBillingInfo(paymentDetails);
      setErrors({ ...errors, ...billingErrors });
      if (Object.keys(errors).length > 0 || Object.keys(billingErrors).length > 0) {
        return;
      }
      // subscription-summary API call and respective checks
      if (!updateCardAndBillingInfo) {
        const summary = await handleAddSubscriptionSummary();
        if (summary.hasErrors) {
          setNotifications([{ type: 'error', content: `${summary.errors[0].message}` }]);
          return;
        }
        setSubscriptionSummary(summary.data);
        setAddSubscriptionLoading('summary');
      }
    }

    // on update billing and card information
    if (updateCardAndBillingInfo) {
      const logValue = {
        userId: account.securityModel.userID,
        subscriptionId: selectedSubscription.userSubscriptionId,
      };
      logActivity('BUTTON', 'updateBillingAndCardInfo', logValue);
      const res = await updateCardAndBilling();
      if (res.hasErrors) {
        setNotifications([{ type: 'error', content: `${res.errors[0].message}` }]);
        return;
      }
      if (!res.data.isSuccess) {
        setNotifications([{ type: 'error', content: `${res.data.formattedMessage}` }]);
        return;
      }
      setNotifications([{ type: 'success', content: `Updated Successfully` }]);
      setUpdateBillingCardInfo(false);
      setSubscriptionCurrentScreen('CurrentSubscription');
      resetState();
      return;
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(getStep(newActiveStep));
  };

  /*
    NOTE: Reset Selected Subscription + Payment Details + PricingModel + UserSubscription (On successful billing-info update)
  */
  const resetState = () => {
    resetSelectedSubscription();
    resetPaymentDetails();
    resetPricingModels();
    resetUserSubscriptionFeaturesData();
    resetUserSubscriptionFeatures();
  };

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsChangeScreenBackButton', reverseStepMap[activeStep]);
    if (updateCardAndBillingInfo) {
      setUpdateBillingCardInfo(false);
      setSubscriptionCurrentScreen('CurrentSubscription');
      return;
    }
    if (activeStep === 0) {
      setSubscriptionCurrentScreen('CurrentSubscription');
      return;
    }
    if (activeStep === 2) {
      setPaymentDetails((prev) => ({ ...prev, cardCvv: '' }));
      setErrors((prev) => ({ ...prev, cardCvv: '' }));
    }
    setActiveStep(getStep(activeStep - 1));
  };

  const getStepsContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <ChangePlan />;
      case 1:
        return <AddOn />;
      case 2:
        return <CardInfoWidget />;
      case 3:
        return <SubscriptionSummary />;
      case 4:
        return <SubscriptionConfirmationWidget />;
      default:
        throw new Error('Unknown screen');
    }
  };

  const renderButtons = () => {
    if (activeStep === 3 || activeStep === 4) {
      return;
    }
    return (
      <Grid item xs={12} container justifyContent="flex-end" pr={2} columnSpacing={2}>
        <Grid item>
          <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="large" variant="outlined">
            <ArrowBackIosIcon fontSize="small" sx={{ color: theme.palette.primary.light }} />
            <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
              {t('common.buttons.back')}
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={updateCardAndBillingInfo ? false : !isActivePlanChanged}
            size="large"
            color="primary"
            onClick={handleNext}>
            <OPBoldTypograpghy variant="button">
              {updateCardAndBillingInfo ? t('common.buttons.update') : t('common.buttons.continue')}
            </OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderStepper = () => {
    if (updateCardAndBillingInfo) {
      return;
    }
    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        sx={{
          backgroundColor: theme.palette.primary.light,
          p: 1,
        }}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={() => handleStep(index)}>
              <OPBoldTypograpghy>{label}</OPBoldTypograpghy>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderStepper()}
        {getStepsContent(activeStep)}
      </Grid>
      <Grid item xs={12}>
        {renderButtons()}
      </Grid>
    </Grid>
  );
};
